import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Navbar, Form, FormControl, Offcanvas, Button, Accordion, Card, Image, Dropdown, Modal, ListGroup} from "react-bootstrap";
import {List as HamburgerIcon, ChevronRight, Telephone} from 'react-bootstrap-icons';
import _ from "lodash";
import {isMobile, isDesktop, isTablet} from 'react-device-detect';
import {
    productNameChanged,
    clearProductNameSearch,
    searchProduct,
    logoutCustomer,
    clearSearchProductState
} from "../actions";
import { Link } from "react-router-dom";

class TopHeader extends Component{

    constructor(props) {

        super(props);

        const history = this.props.history;

        const params = this.props.params;

        const location = this.props.location;

        const width = window.innerWidth;

        const height = window.innerHeight;

        const side_menu_visible = false;

        const account_modal_visible = false;

        const top_category_focused = false;

        const top_category_focused_id = '';

        const category_focused = false;

        const category_focused_id = '';

        this.state = {
            history,
            params,
            location,
            width,
            height,
            side_menu_visible,
            account_modal_visible,
            top_category_focused,
            top_category_focused_id,
            category_focused,
            category_focused_id
        };


    }

    componentDidMount(){

        const { location } = this.state;

        const {  clearSearchProductState} = this.props;

        if(location.pathname !== "/search-product" && !location.pathname.includes("/product/product_id")){

            // Clear search product state for all components having a search bar when mounted

            // EXCEPT search product, product components

            clearSearchProductState();


        }


    }

    renderLogo(){

        return(
            <Image
                src={require("../images/logo.png")}
                style={
                    isMobile ? {
                        width: '200px',
                        height: '100px',
                        marginTop: '10px'
                    } : {
                        width: '150px',
                        height: '100px',
                        marginLeft: '15px',
                        marginRight: '25px'
                    }
                }
                className="logo"
                onClick={() => {
                    this.state.history.push("/");
                }}
            />
        );

    }


    showSideMenu() {
        this.setState({side_menu_visible: true});
    }

    renderMenuTitle(){

        if(!isMobile){

            return(

                <Button
                    style={{
                        color: '#fff',
                        fontSize: 20,
                        border: 'None',
                        background: 'None',
                        margin: '0',
                        padding: '0'
                    }}
                    onClick={() => {
                        this.showSideMenu();
                    }}
                    id="lading-menu-button"
                >
                    Menu
                </Button>

            );

        }

    }

    renderMenu(){

        return(

            <Fragment>

                <HamburgerIcon
                    color="#fff"
                    size={isMobile ? 30 : 20}
                    onClick={() => {
                        this.showSideMenu();
                    }}
                    style={{
                        marginRight: '5px'
                    }}
                />

                {this.renderMenuTitle()}

            </Fragment>

        );

    }


    renderSearchBar(){

        const { productNameChanged, product_name, searchProduct, search_quantity } = this.props;

        const { history, location } = this.state;

        return(

            <Form className="searchbar-container">

                <FormControl
                    type="text"
                    placeholder="Search Bodega Liquidation"
                    className="mr-sm-2 searchbar"
                    style={{
                        borderRadius: '20px',
                        width: isMobile ? this.state.width / 1.2 : '70%',
                        marginLeft: isMobile ? 0 : this.state.width / 18
                    }}
                    onChange={(e) => {
                        e.preventDefault();
                        productNameChanged(e.target.value);
                    }}
                    onKeyPress={(e) => {
                        if(e.charCode === 13){

                            e.preventDefault();

                            searchProduct(product_name, search_quantity);

                            if(location.pathname !== "/search-product"){

                                console.log("go to search product page");

                                history.push("/search-product");

                            }

                        }
                    }}
                    value={product_name}
                />

            </Form>

        );

    }

    renderTopCategories(){

        const { top_categories } = this.props;

        const { history, top_category_focused, top_category_focused_id } = this.state;

        return _.map(top_categories, (top_category, index) => {

            return(

                <Link
                    to={`/category-products/category_id=${top_category.id}`}
                    style={{
                        textDecoration: 'none',
                        color: '#000000'
                    }}
                    key={index}
                >

                    <Button
                        style={{
                            color:  top_category_focused && top_category_focused_id === top_category.id ? '#454342' :  '#fff',
                            fontSize: 20,
                            border: 'None',
                            background: 'None',
                            padding: '0',
                            marginRight: '30px',
                            marginLeft: isMobile ? '30px' : 0
                        }}
                        onMouseEnter={() => {
                            this.setState({top_category_focused: true, top_category_focused_id: top_category.id});
                        }}
                        onMouseLeave={() => {
                            this.setState({top_category_focused: false, top_category_focused_id: top_category.id});
                        }}>
                        {top_category.name}
                    </Button>

                </Link>



            );

        })

    }


    myAccountButton(){

        const { logged_in } = this.props;

        return(

            <Button
                style={{
                    color: '#fff',
                    fontSize: 20,
                    border: 'None',
                    background: 'None',
                    padding: '0',
                    marginRight: isMobile ? '25px' : '25px'
                }}
                id="landing-account-button"
                onClick={() => {
                    this.setState({account_modal_visible: true});
                }}
            >
                {logged_in ? 'My Account' : 'Join Us' }
            </Button>

        );

    }


    numberButton(){

        return(

            <Button
                style={{
                    color: '#fff',
                    border: 'None',
                    background: 'None',
                    padding: '0',
                    marginRight: isMobile ? 0 : '25px'
                }}
                id="landing-phone-button"
                onClick={() => {
                    this.telephoneLink.click();
                }}
            >
                <Telephone style={{marginRight: '5px'}}/>

                <a href="tel:323-582-4444"
                   style={{
                       color: '#fff',
                       fontSize: 20,
                       border: 'None',
                       background: 'None',
                       padding: '0',
                       textDecoration: 'none'
                   }}
                   ref={telephoneLink => this.telephoneLink = telephoneLink}
                >
                    (713)-294-0909
                </a>


            </Button>
        );

    }

    contactUsButton(){

        return(

            <Button
                onClick={() => {
                    window.scroll(0, document.body.offsetHeight);
                }}
                style={{
                    color: '#fff',
                    fontSize: 20,
                    border: 'None',
                    background: 'None',
                    padding: '0',
                    marginRight: isMobile ? 0 : '25px'
                }}
                id="landing-about-us-button"
            >
                Contact Us
            </Button>

        );

    }


    renderHeaderContent(){

        if(isMobile){

            return(


                <Fragment>

                    <Navbar
                        className="landing-navbar"
                        style={{
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column'
                        }}
                    >




                        <div style={{
                            marginBottom: '5px'
                        }}>

                            {this.renderLogo()}

                        </div>


                        <div style={{
                            marginBottom: '10px'
                        }}>
                            {this.numberButton()}

                        </div>

                        <div style={{
                            marginBottom: '5px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}>

                            {this.myAccountButton()}

                            {this.contactUsButton()}

                        </div>





                        <div style={{
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'row'
                        }}>

                            {this.renderMenu()}

                            {this.renderSearchBar()}

                        </div>





                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            overflow: 'auto',
                            whiteSpace: 'nowrap',
                            marginLeft: '20px',
                            borderTop: '0.5px solid #fff',
                            paddingTop: '1rem'
                        }}
                             id="top-categories-mobile-scroll"
                        >


                            {this.renderTopCategories()}



                        </div>






                    </Navbar>

                    <Image
                        src={require("../images/fading.png")}
                        style={{
                            width: '100%',
                            height: '40px',
                            marginTop: 0,
                            marginLeft: 0,
                            marginRight: 0,
                            marginBottom: '1.5rem'
                        }}
                    />

                </Fragment>







            );



        }else{

            return(

                <Fragment>

                    <Navbar
                        className="landing-navbar"
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                        sticky="top"
                    >


                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            marginBottom: '0.5rem',
                            borderBottom: '0.5px solid #fff'
                        }}>

                            <div>


                                {this.renderLogo()}

                                {this.renderMenu()}

                            </div>



                            {this.renderSearchBar()}

                            {this.myAccountButton()}

                            {this.contactUsButton()}

                            {this.numberButton()}


                        </div>



                    </Navbar>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: this.state.width,
                        height: '30px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: 0,
                        marginTop: 0,
                        backgroundColor: '#AC0A0A'
                    }}>


                        {this.renderTopCategories()}



                    </div>

                    <Image
                        src={require("../images/fading.png")}
                        style={{
                            width: '100%',
                            height: '40px',
                            marginTop: 0,
                            marginLeft: 0,
                            marginRight: 0,
                            marginBottom: '1rem'
                        }}
                    />


                </Fragment>

            );

        }

    }


    renderSubcategories(subcategories){

        const { history, category_focused, category_focused_id} = this.state;

        return _.map(subcategories, (subcategory) => {

            const child_subcategories = subcategory.subcategories;

            if(child_subcategories === undefined || _.isEmpty(child_subcategories)){

                return(

                    <Card
                        key={subcategory.id}
                        style={{
                            marginBottom: '10px',
                            borderColor:  category_focused && category_focused_id === subcategory.id ? 'blue' : ''
                        }}
                        onMouseEnter={() => {
                            this.setState({category_focused: true, category_focused_id: subcategory.id});
                        }}
                        onMouseLeave={() => {
                            this.setState({category_focused: false, category_focused_id: subcategory.id});
                        }}
                        onClick={() => {
                            history.push(`/category/category_id=${subcategory.id}`);
                            this.setState({side_menu_visible: false});
                        }}
                    >

                        <div className="category-items-container">

                            <Card.Body>{subcategory.name}</Card.Body>

                            <ChevronRight color="royalblue" size={20} className="category-chevron-icon"/>

                        </div>


                    </Card>

                );

            }else{

                return(

                    <Accordion
                        key={subcategory.id}
                    >

                        <Card
                            style={{
                                marginBottom: '10px',
                                borderColor:  category_focused && category_focused_id === subcategory.id ? 'blue' : ''
                            }}
                            onMouseEnter={() => {
                                this.setState({category_focused: true, category_focused_id: subcategory.id});
                            }}
                            onMouseLeave={() => {
                                this.setState({category_focused: false, category_focused_id: subcategory.id});
                            }}
                        >

                            <Accordion.Item eventKey={subcategory.id}>

                                <Accordion.Header>{subcategory.name}</Accordion.Header>

                                <Accordion.Body>

                                    {this.renderSubcategories(child_subcategories)}

                                    {this.renderParentCategory(subcategory)}

                                </Accordion.Body>

                            </Accordion.Item>


                        </Card>

                    </Accordion>

                );

            }





        });

    }

    renderParentCategory(category){

        const { history, category_focused_id, category_focused } = this.state;

        if(category.has_products){

            return(


                <Card
                    key={category.id}
                    style={{
                        marginBottom: '10px',
                        borderColor:  category_focused && category_focused_id === category.id ? 'blue' : ''
                    }}
                    onMouseEnter={() => {
                        this.setState({category_focused: true, category_focused_id: category.id});
                    }}
                    onMouseLeave={() => {
                        this.setState({category_focused: false, category_focused_id: category.id});
                    }}
                    onClick={() => {
                        history.push(`/category/category_id=${category.id}`);
                        this.setState({side_menu_visible: false});
                    }}
                >
                    <div className="category-items-container">

                        <Card.Body>View Other {category.name}</Card.Body>

                        <ChevronRight color="royalblue" size={20} className="category-chevron-icon"/>

                    </div>


                </Card>


            );

        }

    }


    renderCategoriesList(){

        const { header_categories } = this.props;

        const { history, category_focused_id, category_focused} = this.state;

        return _.map(header_categories, (category) => {

            const subcategories = category.subcategories;

            if(subcategories !== null && subcategories !== undefined && !_.isEmpty(subcategories)){

                return(

                    <Card
                        key={category.id}
                        style={{
                            marginBottom: '10px',
                            borderColor:  category_focused && category_focused_id === category.id ? 'blue' : ''
                        }}
                        onMouseEnter={() => {
                            this.setState({category_focused: true, category_focused_id: category.id});
                        }}
                        onMouseLeave={() => {
                            this.setState({category_focused: false, category_focused_id: category.id});
                        }}
                    >


                        <Accordion.Item eventKey={category.id}>

                            <Accordion.Header>{category.name}</Accordion.Header>

                            <Accordion.Body>

                                {this.renderSubcategories(subcategories)}

                                {this.renderParentCategory(category)}

                            </Accordion.Body>

                        </Accordion.Item>


                    </Card>


                );


            }else{

                return(

                    <Card
                        key={category.id}
                        style={{
                            marginBottom: '10px',
                            borderColor:  category_focused && category_focused_id === category.id ? 'blue' : ''
                        }}
                        onMouseEnter={() => {
                            this.setState({category_focused: true, category_focused_id: category.id});
                        }}
                        onMouseLeave={() => {
                            this.setState({category_focused: false, category_focused_id: category.id});
                        }}
                        onClick={() => {
                            history.push(`/category/category_id=${category.id}`);
                            this.setState({side_menu_visible: false});
                        }}
                    >

                        <div className="category-items-container">

                            <Card.Body>{category.name}</Card.Body>

                            <ChevronRight color="royalblue" size={20} className="category-chevron-icon"/>

                        </div>


                    </Card>

                );


            }

        });


    }

    renderCategories(){

        const { header_categories} = this.props;

        if(header_categories !== null && header_categories !== undefined && !_.isEmpty(header_categories)){


            return(

                <Accordion>

                    {this.renderCategoriesList()}

                </Accordion>

            );


        }else{

            return <div/>;

        }
    }

    sideMenu() {

        const {side_menu_visible} = this.state;

        if (side_menu_visible) {

            return (

                <Offcanvas
                    show={side_menu_visible}
                    onHide={() => this.setState({side_menu_visible: false})}
                    style={{
                        width: isMobile ? this.state.width : this.state.width / 2.5,
                        backgroundColor: '#f7f1eb'
                    }}
                >

                    <Offcanvas.Header closeButton>

                        <Offcanvas.Title>Categories</Offcanvas.Title>

                    </Offcanvas.Header>

                    <Offcanvas.Body>

                        {this.renderCategories()}

                    </Offcanvas.Body>

                </Offcanvas>

            );

        }

    }


    exitAccountModal(){

        this.setState({account_modal_visible: false});

    }

    renderRegisterButton(){

        const { location, history } = this.state;

        const { logged_in } = this.props;

        if(location.pathname !== "/register" && !logged_in){

            return(

                <Card
                    style={{marginBottom: '20px'}}
                    onClick={() => {

                        history.push("/register");

                    }}
                >
                    <div id="account-option-container">

                        <Card.Body id="account-option-title">Register</Card.Body>

                        <ChevronRight color="royalblue" size={20} id="account-chevron-icon"/>

                    </div>


                </Card>

            );

        }


    }

    renderLoginButton(){

        const { location, history } = this.state;

        const { logged_in } = this.props;

        console.log(`logged_in is `, logged_in);

        if(location.pathname !== "/login" && !logged_in){

            return(

                <Card
                    style={{marginBottom: '20px'}}
                    onClick={() => {

                        history.push("/login");

                    }}
                >
                    <div id="account-option-container">

                        <Card.Body id="account-option-title">Login</Card.Body>

                        <ChevronRight color="royalblue" size={20} id="account-chevron-icon"/>

                    </div>


                </Card>

            );

        }

    }


    renderLogoutButton(){

        const {  history } = this.state;

        const { logged_in, access_token, client, uid , logoutCustomer} = this.props;

        if(logged_in){

            return(

                <Card
                    style={{marginBottom: '20px'}}
                    onClick={() => {

                        logoutCustomer(access_token, client, uid, history);

                        this.exitAccountModal();

                    }}
                >
                    <div id="account-option-container">

                        <Card.Body id="account-option-title">Logout</Card.Body>

                        <ChevronRight color="royalblue" size={20} id="account-chevron-icon"/>

                    </div>


                </Card>

            );

        }


    }


    renderProfileButton(){

        const {  history } = this.state;

        const { logged_in } = this.props;

        if(logged_in){

            return(

                <Card
                    style={{marginBottom: '20px'}}
                    onClick={() => {

                        history.push("/profile");

                    }}
                >
                    <div id="account-option-container">

                        <Card.Body id="account-option-title">Profile</Card.Body>

                        <ChevronRight color="royalblue" size={20} id="account-chevron-icon"/>

                    </div>


                </Card>

            );

        }


    }


    accountModal(){

        const { account_modal_visible, history } = this.state;

        const { logged_in } = this.props;

        if(account_modal_visible){

            return(

                <Modal
                    size={isMobile ? 'sm' : 'lg'}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={account_modal_visible}
                    onHide={() => {
                        this.exitAccountModal();
                    }}
                >
                    <Modal.Header closeButton>

                        <Modal.Title id="contained-modal-title-vcenter">
                            {logged_in ? 'My Account' : 'Get Started'}
                        </Modal.Title>
                        

                    </Modal.Header>

                    <Modal.Body>

                        {this.renderRegisterButton()}

                        {this.renderLoginButton()}

                        {this.renderProfileButton()}

                        {this.renderLogoutButton()}

                    </Modal.Body>


                    <Modal.Footer>

                        <Button
                            variant="secondary"
                            onClick={() => {
                                this.exitAccountModal();
                            }}>
                            Close
                        </Button>

                    </Modal.Footer>

                </Modal>

            );

        }

    }

    render(){

        return(

            <Fragment>

                {this.renderHeaderContent()}

                {this.sideMenu()}

                {this.accountModal()}

            </Fragment>


        );


    }



}

const mapStateToProps = (state) => {

    const {
        header_categories,
        top_categories
    } = state.user_pages;

    const {
        product_name,
        search_quantity
    } = state.search_product;

    const {
        logged_in,
        access_token,
        client,
        uid
    } = state.login;

    return {
        header_categories,
        top_categories,
        product_name,
        search_quantity,
        logged_in,
        access_token,
        client,
        uid
    };


};


export default connect(mapStateToProps, {
    productNameChanged,
    clearProductNameSearch,
    searchProduct,
    logoutCustomer,
    clearSearchProductState
})(TopHeader);