import {
    SEARCH_PRODUCT_ROUTE,
    SEARCH_PRODUCT,
    CLEAR_PRODUCT_NAME_SEARCH,
    PRODUCT_NAME_CHANGED,
    CLEAR_SEARCH_PRODUCT_STATE,
    SEARCH_PRODUCT_COMPLETE,
    SEARCH_PRODUCT_FAILURE,
    SEARCH_QUANTITY_CHANGED,
    EXIT_SEARCH_PRODUCT_PAGE
} from "./types";
import axios from "axios";
import { getFormData } from "../helpers";
import _ from "lodash";


export const exitSearchProductPage = (xoffset, yoffset) => {

    return{
        type: EXIT_SEARCH_PRODUCT_PAGE,
        payload: { xoffset: xoffset, yoffset: yoffset }
    };

};



export const clearSearchProductState = () => {

    return{
        type: CLEAR_SEARCH_PRODUCT_STATE
    };

};

export const productNameChanged = (product_name) => {

    return{
        type: PRODUCT_NAME_CHANGED,
        payload: product_name
    };

};

export const clearProductNameSearch = () => {

    return{
        type: CLEAR_PRODUCT_NAME_SEARCH
    };

};

export const searchProduct = (product_name, search_quantity) => {

    return(dispatch) => {

        if(product_name !== null && product_name !== undefined){

            // sanity check, product name initialize product name as string in reducer

            product_name = product_name.trim();

            dispatch({type: SEARCH_PRODUCT});


            console.log(`Fetching results for ${product_name}`);

            const config = {
                headers: {
                    "Accept": "application/json"
                }
            };

            let bodyFormData = getFormData({
                name: product_name,
                quantity: search_quantity
            });


            axios.post(SEARCH_PRODUCT_ROUTE, bodyFormData, config)
                .then(response => {

                    const data = response.data;

                    const products = data.products;

                    console.log(products);

                    dispatch({type: SEARCH_PRODUCT_COMPLETE, payload: products});

                    search_quantity += 50;

                    dispatch({type: SEARCH_QUANTITY_CHANGED, payload: search_quantity})



                }).catch(error => {

                dispatch({type: SEARCH_PRODUCT_FAILURE});

                console.log(error);

            });




        }



    }

};