import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';

import {
    LOGIN_CUSTOMER,
    LOGIN_CUSTOMER_SUCCESS,
    LOGIN_CUSTOMER_FAILURE,
    LOGOUT_SUCCESS,
    CLEAR_LOGIN_FORM,
    CUSTOMER_UID_CHANGED
} from "../actions/types";

const INITIAL_STATE = {
    access_token: '',
    client: '',
    uid: '',
    logged_in: false,
    loading: false,
    errors: []
};

const LoginReducer =  (state = INITIAL_STATE , action) => {

    switch (action.type) {
        case CUSTOMER_UID_CHANGED:
            return{
                ...state,
                uid: action.payload
            };
        case CLEAR_LOGIN_FORM:
            return{
                ...state,
                errors: []
            };
        case LOGIN_CUSTOMER_SUCCESS:
            return{
                ...state,
                loading: false,
                access_token: action.payload.access_token,
                client: action.payload.client,
                uid: action.payload.uid,
                logged_in: true
            };
        case LOGIN_CUSTOMER_FAILURE:
            return{
                ...state,
                loading: false,
                errors: action.payload
            };
        case LOGIN_CUSTOMER:
            return{
                ...state,
                loading: true,
                errors: []
            };
        case LOGOUT_SUCCESS:
            return{
                ...INITIAL_STATE
            };
        default:
            return state;
    }

};

const persistConfig = {
    key: 'login',
    storage,
    whitelist: [
        'access_token',
        'client',
        'uid',
        'logged_in'
    ],
    stateReconciler: autoMergeLevel1
};

export default persistReducer(persistConfig, LoginReducer);
