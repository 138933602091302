export const BACKEND_DOMAIN =  ( !process.env.NODE_ENV || process.env.NODE_ENV === 'development')  ?  "localhost:3000" : "api.bodegaliquidation.com";
export const BACKEND_URL =  `${ ( !process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'http' : 'https'}://${BACKEND_DOMAIN}`;

// PhoneNumber Types
export const SEND_SMS_VERIFICATION_ROUTE = `${BACKEND_URL}/send-sms-verification-code`;
export const SEND_SMS_VERIFICATION = "send_sms_verification";
export const SEND_SMS_VERIFICATION_COMPLETE = "send_sms_verification_complete";
export const SEND_SMS_VERIFICATION_SUCCESS = "send_sms_verification_success";
export const SEND_SMS_VERIFICATION_FAILURE = "send_sms_verification_failure";
export const EXIT_SMS_VERIFICATION_MODAL = "exit_sms_verification_modal";
export const OPEN_SMS_VERIFICATION_MODAL = "open_sms_verification_modal";
export const CLEAR_PHONE_NUMBER_STATE = "clear_phone_number_state";
export const CHECK_SMS_VERIFICATION_CODE = "check_sms_verification_code";
export const CHECK_SMS_VERIFICATION_CODE_COMPLETE = "check_sms_verification_code_complete";
export const CHECK_SMS_VERIFICATION_CODE_FAILURE = "check_sms_verification_code_failure";
export const CHECK_SMS_VERIFICATION_CODE_ROUTE = `${BACKEND_URL}/check-sms-verification-code`;
export const RESEND_SMS_VERIFICATION_CODE = "resend_sms_verification_code";
export const RESEND_SMS_VERIFICATION_CODE_COMPLETE = "resend_sms_verification_code_complete";
export const RESEND_SMS_VERIFICATION_CODE_FAILURE = "resend_sms_verification_code_failure";

// Profile Types
export const PROFILE_PAGE_ROUTE = `${BACKEND_URL}/profile-index`;
export const INITIALIZE_PROFILE_PAGE = "initialize_profile_page";
export const INITIALIZE_PROFILE_PAGE_COMPLETE = "initialize_profile_page_complete";
export const CLEAR_PROFILE_PAGE_STATE = "clear_profile_page_state";
export const CUSTOMER_CHANGE_EMAIL_ROUTE = `${BACKEND_URL}/customer-change-email`;
export const CUSTOMER_CHANGE_EMAIL = "customer_change_email";
export const CUSTOMER_CHANGE_EMAIL_COMPLETE = "customer_change_email_complete";
export const CUSTOMER_CHANGE_EMAIL_SUCCESS = "customer_change_email_success";
export const CUSTOMER_CHANGE_EMAIL_FAILURE = "customer_change_email_failure";
export const OPEN_CUSTOMER_CHANGE_EMAIL_MODAL = "open_customer_change_email_modal";
export const CLOSE_CUSTOMER_CHANGE_EMAIL_MODAL = "close_customer_change_email_modal";
export const NEW_CUSTOMER_EMAIL_CHANGED = "new_customer_email_changed";

// ResetPassword Types
export const RESET_PASSWORD_ROUTE = `${BACKEND_URL}/customer_auth/password`;
export const RESET_PASSWORD = "reset_password";
export const RESET_PASSWORD_COMPLETE = "reset_password_complete";
export const CLEAR_RESET_PASSWORD_STATE = "clear_reset_password_state";
export const RESET_PASSWORD_FORM_ERROR_CHANGED = "reset_password_form_error_changed";

// Login Types
export const LOGIN_ROUTE = `${BACKEND_URL}/customer_auth/sign_in`;
export const LOGIN_CUSTOMER = "login_customer";
export const LOGIN_CUSTOMER_SUCCESS = "login_customer_success";
export const LOGIN_CUSTOMER_FAILURE = "login_customer_failure";
export const LOGOUT_SUCCESS = "logout_success";
export const LOGOUT_ROUTE = `${BACKEND_URL}/customer_auth/sign_out`;
export const CLEAR_LOGIN_FORM = "clear_login_form";
export const CUSTOMER_UID_CHANGED = "customer_uid_changed";


// SendResetLink Types
export const SEND_RESET_LINK_ROUTE = `${BACKEND_URL}/customer_auth/password`;
export const SEND_RESET_LINK = "send_reset_link";
export const SEND_RESET_LINK_SUCCESS = "send_reset_link_success";
export const SEND_RESET_LINK_FAILURE = "send_reset_link_failure";
export const SEND_RESET_LINK_COMPLETE = "send_reset_link_complete";
export const CLEAR_SEND_RESET_LINK_STATE = "clear_send_reset_link_state";


// ResendUnlock Types
export const RESEND_UNLOCK_ROUTE = `${BACKEND_URL}/customer_auth/unlock`;
export const RESEND_UNLOCK = "resend_unlock";
export const RESEND_UNLOCK_SUCCESS = "resend_unlock_success";
export const RESEND_UNLOCK_FAILURE = "resend_unlock_failure";
export const RESEND_UNLOCK_COMPLETE = "resend_unlock_complete";
export const CLEAR_RESEND_UNLOCK_STATE = "clear_resend_unlock_state";

// ResendConfirmation Types
export const RESEND_CONFIRMATION_ROUTE = `${BACKEND_URL}/customer_auth/confirmation`;
export const RESEND_CONFIRMATION = "resend_confirmation";
export const RESEND_CONFIRMATION_SUCCESS = "resend_confirmation_success";
export const RESEND_CONFIRMATION_FAILURE = "resend_confirmation_failure";
export const RESEND_CONFIRMATION_COMPLETE = "resend_confirmation_complete";
export const CLEAR_RESEND_CONFIRMATION_STATE = "clear_resend_confirmation_state";


export const VERIFY_RECAPTCHA_TOKEN_ROUTE = `${BACKEND_URL}/verify-recaptcha-token`;

// Register Types
export const REGISTER_CUSTOMER_ROUTE = `${BACKEND_URL}/customer_auth`;
export const REGISTER_CUSTOMER = "register_customer";
export const REGISTER_CUSTOMER_COMPLETE = "register_customer_complete";
export const REGISTER_CUSTOMER_SUCCESS = "register_customer_success";
export const REGISTER_CUSTOMER_FAILURE = "register_customer_failure";
export const REGISTER_CUSTOMER_EMAIL_CHANGED = "register_customer_email_changed";
export const REGISTER_CUSTOMER_PASSWORD_CHANGED = "register_customer_password_changed";
export const REGISTER_CUSTOMER_PASSWORD2_CHANGED = "register_customer_password2_changed";
export const REGISTER_CUSTOMER_FULL_NAME_CHANGED = "register_customer_full_name_changed";
export const REGISTER_CUSTOMER_COMPANY_NAME_CHANGED = "register_customer_company_name_changed";
export const REGISTER_CUSTOMER_COMPANY_ADDRESS_CHANGED = "register_customer_company_address_changed";
export const REGISTER_CUSTOMER_COMPANY_CITY_CHANGED = "register_customer_company_city_changed";
export const REGISTER_CUSTOMER_COMPANY_ZIP_CODE_CHANGED = "register_customer_company_zip_code_changed";
export const REGISTER_CUSTOMER_COMPANY_STATE_CHANGED = "register_customer_company_state_changed";
export const CLEAR_REGISTER_CUSTOMER_FORM = "clear_register_customer_form";
export const CLEAR_REGISTER_CUSTOMER_STATE = "clear_register_customer_state";
export const CLEAR_REGISTER_CUSTOMER_ERRORS = "clear_register_customer_errors";
export const REGISTER_CUSTOMER_EMAIL_ERROR = "register_customer_email_error";
export const REGISTER_CUSTOMER_PASSWORD_ERROR = "register_customer_password_error";
export const REGISTER_CUSTOMER_PASSWORD2_ERROR = "register_customer_password2_error";
export const REGISTER_CUSTOMER_FULL_NAME_ERROR = "register_customer_full_name_error";
export const REGISTER_CUSTOMER_COMPANY_NAME_ERROR = "register_customer_company_name_error";
export const REGISTER_CUSTOMER_COMPANY_ADDRESS_ERROR = "register_customer_company_address_error";
export const REGISTER_CUSTOMER_COMPANY_CITY_ERROR = "register_customer_company_city_error";
export const REGISTER_CUSTOMER_COMPANY_ZIP_CODE_ERROR = "register_customer_company_zip_code_error";
export const REGISTER_CUSTOMER_COMPANY_STATE_ERROR = "register_customer_company_state_error";
export const REGISTER_CUSTOMER_SELLER_PERMIT_CHANGED = "register_customer_seller_permit_changed";
export const REGISTER_CUSTOMER_SELLER_PERMIT_ERROR = "register_customer_seller_permit_error";
export const HIDE_REGISTER_CUSTOMER_MODAL = "hide_register_customer_modal";
export const REGISTER_STATE_CODE_CHANGED = "register_state_code_changed";
export const REGISTER_CUSTOMER_EMAIL_CONSENT_GIVEN_CHANGED = "register_customer_email_consent_given_changed";

// CategoryProducts Types
export const CATEGORY_PRODUCTS_ROUTE = `${BACKEND_URL}/get-category-products`;
export const INITIALIZE_CATEGORY_PRODUCTS_PAGE = "initialize_category_products_page";
export const INITIALIZE_CATEGORY_PRODUCTS_PAGE_SUCCESS = "initialize_category_products_page_success";
export const CLEAR_CATEGORY_PRODUCTS_STATE = "clear_category_products_state";
export const CATEGORY_PRODUCTS_QUANTITY_CHANGED = "category_products_quantity_changed";
export const LOAD_CATEGORY_PRODUCTS = "load_category_products";
export const LOAD_CATEGORY_PRODUCTS_SUCCESS = "load_category_products_success";
export const EXIT_CATEGORY_PRODUCTS_PAGE = "exit_category_products_page";

// Product Types
export const VIEW_PRODUCT_ROUTE = `${BACKEND_URL}/user-view-product`;
export const FETCH_PRODUCT_DATA = "fetch_product_data";
export const FETCH_PRODUCT_DATA_COMPLETE = "fetch_product_data_complete";
export const CLEAR_PRODUCT_PAGE_STATE = "clear_product_page_state";

// Category Types
export const VIEW_CATEGORY_ROUTE = `${BACKEND_URL}/user-view-category`;
export const FETCH_CATEGORY_DATA = "fetch_category_data";
export const FETCH_CATEGORY_DATA_COMPLETE = "fetch_category_data_complete";
export const CLEAR_CATEGORY_PAGE_STATE = "clear_category_page_state";
export const EXIT_CATEGORY_PAGE = "exit_category_page";

// SearchProductTypes
export const SEARCH_PRODUCT_ROUTE = `${BACKEND_URL}/user-search-product`;
export const SEARCH_PRODUCT = "search_product";
export const SEARCH_PRODUCT_COMPLETE = "search_product_complete";
export const SEARCH_PRODUCT_FAILURE = "search_product_failure";
export const CLEAR_PRODUCT_NAME_SEARCH = "clear_product_name_search";
export const PRODUCT_NAME_CHANGED = "product_name_changed";
export const CLEAR_SEARCH_PRODUCT_STATE = "clear_search_product_state";
export const SEARCH_QUANTITY_CHANGED = "search_quantity_changed";
export const EXIT_SEARCH_PRODUCT_PAGE = "exit_search_product_page";

// Landing Page Types
export const INITIALIZE_LANDING_PAGE_ROUTE = `${BACKEND_URL}/initialize-landing-page`;
export const INITIALIZE_LANDING_PAGE = "initialize_landing_page";
export const INITIALIZE_LANDING_PAGE_COMPLETE = "initialize_landing_page_complete";

// User Pages Types
export const GET_HEADER_CATEGORIES_ROUTE = `${BACKEND_URL}/get-header-categories`;
export const INITIALIZE_USER_PAGES = "initialize_user_pages";
export const INITIALIZE_USER_PAGES_COMPLETE = "initialize_user_pages_complete";