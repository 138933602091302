import React from 'react';
import _ from "lodash";

export const makeProductBrandNameBold = (product_name, brand_name) => {


    if(brand_name !== undefined && brand_name !== null && !_.isEmpty(brand_name)){

        let brand_name_array = brand_name.split("™");

        if(product_name.includes(brand_name_array[0])){

            if(product_name.includes(`${brand_name_array[0]}™`)){

                product_name = product_name.replace(`${brand_name_array[0]}™`, `<strong>${brand_name_array[0]}™</strong>`);

            }else{

                product_name = product_name.replace(brand_name_array[0], `<strong>${brand_name_array[0]}™</strong>`);

            }


            return(

                <span dangerouslySetInnerHTML={{ __html: product_name }}  />

            );


        } else{

            return(

                <span>{product_name}</span>

            );

        }


    }else{

        return(

            <span>{product_name}</span>

        );

    }

};