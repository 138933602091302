import {
    INITIALIZE_CATEGORY_PRODUCTS_PAGE,
    INITIALIZE_CATEGORY_PRODUCTS_PAGE_SUCCESS,
    CLEAR_CATEGORY_PRODUCTS_STATE,
    CATEGORY_PRODUCTS_QUANTITY_CHANGED,
    LOAD_CATEGORY_PRODUCTS,
    LOAD_CATEGORY_PRODUCTS_SUCCESS,
    EXIT_CATEGORY_PRODUCTS_PAGE
} from "../actions/types";

const INITIAL_STATE = {
    fetching_category_products: false,
    category_name: '',
    products: [],
    products_quantity: 15,
    loading_category_products: false,
    previous_category_id: null,
    xoffset: null,
    yoffset: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EXIT_CATEGORY_PRODUCTS_PAGE:
            return{
                ...state,
                fetching_category_products: false,
                loading_category_products: false,
                xoffset: action.payload.xoffset,
                yoffset: action.payload.yoffset
            };
        case LOAD_CATEGORY_PRODUCTS_SUCCESS:
            return{
                ...state,
                loading_category_products: false,
                products: action.payload
            };
        case LOAD_CATEGORY_PRODUCTS:
            return{
                ...state,
                loading_category_products: true
            };
        case CATEGORY_PRODUCTS_QUANTITY_CHANGED:
            return{
                ...state,
                products_quantity: action.payload
            };
        case INITIALIZE_CATEGORY_PRODUCTS_PAGE_SUCCESS:
            return{
                ...state,
                fetching_category_products: false,
                category_name: action.payload.category_name,
                products: action.payload.products,
                previous_category_id: action.payload.previous_category_id,
                products_quantity: action.payload.products_quantity
            };
        case INITIALIZE_CATEGORY_PRODUCTS_PAGE:
            return{
                ...state,
                fetching_category_products: true
            };
        case CLEAR_CATEGORY_PRODUCTS_STATE:
            return{
                ...state,
                ...INITIAL_STATE
            };
        default:
            return state;
    }
};
