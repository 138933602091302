import {
    FETCH_CATEGORY_DATA,
    FETCH_CATEGORY_DATA_COMPLETE,
    CLEAR_CATEGORY_PAGE_STATE,
    EXIT_CATEGORY_PAGE
} from "../actions/types";

const INITIAL_STATE = {
    fetching_category_data: false,
    category_name: '',
    products: [],
    parent_categories: [],
    subcategories: [],
    previous_category_id: null,
    xoffset: null,
    yoffset: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EXIT_CATEGORY_PAGE:
            return{
                ...state,
                fetching_category_data: false,
                xoffset: action.payload.xoffset,
                yoffset: action.payload.yoffset
            };
        case FETCH_CATEGORY_DATA_COMPLETE:
            return{
                ...state,
                fetching_category_data: false,
                category_name: action.payload.category_name,
                products: action.payload.products,
                parent_categories: action.payload.parent_categories,
                subcategories: action.payload.subcategories,
                previous_category_id: action.payload.previous_category_id
            };
        case FETCH_CATEGORY_DATA:
            return{
                ...state,
                fetching_category_data: true
            };
        case CLEAR_CATEGORY_PAGE_STATE:
            return{
                ...state,
                ...INITIAL_STATE
            };
        default:
            return state;
    }
};
