import React, {Fragment} from "react";
import { ArrowUpCircle } from "react-bootstrap-icons";
import {isMobile } from 'react-device-detect'


export const ScrollTop = () => {

  return(

      <ArrowUpCircle
          size={isMobile ? 30 : 40}
          className="hover-button"
          style={
              isMobile ? { right: '25px' } : {}
          }
          onClick={() => {
              window.scrollTo(0, 0)
          }}
      />

  )


};

