import {
    FETCH_PRODUCT_DATA,
    FETCH_PRODUCT_DATA_COMPLETE,
    CLEAR_PRODUCT_PAGE_STATE
} from "../actions/types";

const INITIAL_STATE = {
    fetching_product_data: false,
    product: {},
    similar_items: [],
    parent_categories: [],
    category_name: '',
    category_id: '',
    pack_size: '',
    price_ranges: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_PRODUCT_DATA_COMPLETE:
            return{
                ...state,
                fetching_product_data: false,
                product: action.payload.product,
                similar_items: action.payload.similar_items,
                parent_categories: action.payload.parent_categories,
                category_name: action.payload.category_name,
                category_id: action.payload.category_id,
                pack_size: action.payload.pack_size === undefined ? '' : action.payload.pack_size,
                price_ranges: action.payload.price_ranges === undefined ? [] : action.payload.price_ranges
            };
        case FETCH_PRODUCT_DATA:
            return{
                ...state,
                fetching_product_data: true
            };
        case CLEAR_PRODUCT_PAGE_STATE:
            return{
                ...state,
                ...INITIAL_STATE
            };
        default:
            return state;
    }
};
