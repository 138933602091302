import {
    VIEW_PRODUCT_ROUTE,
    FETCH_PRODUCT_DATA,
    FETCH_PRODUCT_DATA_COMPLETE,
    CLEAR_PRODUCT_PAGE_STATE
} from "./types";

import axios from "axios";
import { getFormData } from "../helpers";
import _ from "lodash";

export const clearProductPageState = () => {

    return{
        type: CLEAR_PRODUCT_PAGE_STATE
    };

};

export const fetchProductData = (access_token, client, uid,  history, product_id) => {

    return(dispatch) => {

        dispatch({type: FETCH_PRODUCT_DATA});

        console.log(`Fetching product data for product with id ${product_id}`);

        const config = {
            headers: {
                "access-token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        let bodyFormData = getFormData({
            id: product_id
        });

        axios.post(VIEW_PRODUCT_ROUTE, bodyFormData, config)
            .then(response => {

                const data = response.data;

                console.log(data);

                const success = data.success;

                if(success){

                    const product = data.product;

                    const similar_items = data.similar_items;

                    const parent_categories = data.parent_categories;

                    const category_name = data.category_name;

                    const category_id = data.category_id;

                    const pack_size = data.pack_size;

                    const price_ranges = data.price_ranges;

                    dispatch({type: FETCH_PRODUCT_DATA_COMPLETE, payload: {
                        product: product,
                        similar_items: similar_items,
                        parent_categories: parent_categories,
                        category_name: category_name,
                        category_id: category_id,
                        pack_size: pack_size,
                        price_ranges: price_ranges
                    }});


                }else{

                    const message = data.message;

                    console.log(message);

                    history.push("/");

                    dispatch({type: CLEAR_PRODUCT_PAGE_STATE});
                }


            }).catch(error => {

            history.push("/");

            dispatch({type: CLEAR_PRODUCT_PAGE_STATE});

            console.log(error);

        });

    };

};