import {
    SEARCH_PRODUCT,
    SEARCH_PRODUCT_FAILURE,
    CLEAR_PRODUCT_NAME_SEARCH,
    PRODUCT_NAME_CHANGED,
    CLEAR_SEARCH_PRODUCT_STATE,
    SEARCH_PRODUCT_COMPLETE,
    SEARCH_QUANTITY_CHANGED,
    EXIT_SEARCH_PRODUCT_PAGE
} from "../actions/types";

const INITIAL_STATE = {
    searching_product: false,
    products: [],
    product_name: '',
    search_quantity: 50,
    xoffset: null,
    yoffset: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EXIT_SEARCH_PRODUCT_PAGE:
            return{
                ...state,
                searching_product: false,
                product_name: state.product_name,
                xoffset: action.payload.xoffset,
                yoffset: action.payload.yoffset
            };
        case SEARCH_QUANTITY_CHANGED:
            return{
                ...state,
                search_quantity: action.payload
            };
        case CLEAR_SEARCH_PRODUCT_STATE:
            return{
                ...state,
                ...INITIAL_STATE
            };
        case PRODUCT_NAME_CHANGED:
            return{
                ...state,
                product_name: action.payload
            };
        case CLEAR_PRODUCT_NAME_SEARCH:
            return{
                ...state,
                product_name: ''
            };
        case SEARCH_PRODUCT_COMPLETE:
            return{
                ...state,
                searching_product: false,
                products: action.payload
            };
        case SEARCH_PRODUCT_FAILURE:
            return{
                ...state,
                searching_product: false
            };
        case SEARCH_PRODUCT:
            return{
                ...state,
                searching_product: true
            };
        default:
            return state;
    }
};
