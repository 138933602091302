import {combineReducers} from 'redux';
import storage from 'redux-persist/lib/storage';
import { LOGOUT_SUCCESS } from "../actions/types";

import LandingReducer from "./LandingReducer";
import UserPagesReducer from "./UserPagesReducer";
import SearchProductReducer from "./SearchProductReducer";
import CategoryReducer from "./CategoryReducer";
import ProductReducer from  "./ProductReducer";
import CategoryProductsReducer from "./CategoryProductsReducer";
import RegisterReducer from "./RegisterReducer";
import ResendConfirmationReducer from "./ResendConfirmationReducer";
import ResendUnlockReducer from "./ResendUnlockReducer";
import SendResetLinkReducer from "./SendResetLinkReducer";
import LoginReducer from "./LoginReducer";
import ResetPasswordReducer from "./ResetPasswordReducer";
import ProfileReducer from "./ProfileReducer";
import PhoneNumberReducer from "./PhoneNumberReducer";


const appReducer = combineReducers({
    landing: LandingReducer,
    user_pages: UserPagesReducer,
    search_product: SearchProductReducer,
    category: CategoryReducer,
    product: ProductReducer,
    category_products: CategoryProductsReducer,
    register_customer: RegisterReducer,
    resend_confirmation: ResendConfirmationReducer,
    resend_unlock: ResendUnlockReducer,
    send_reset_link: SendResetLinkReducer,
    login: LoginReducer,
    reset_password: ResetPasswordReducer,
    profile: ProfileReducer,
    phone_number: PhoneNumberReducer
});


const rootReducer = ( state, action ) => {

    if ( action.type === LOGOUT_SUCCESS ) {

        storage.removeItem('login:access_token').then(() => console.log("access_token removed"));

        storage.removeItem('login:client').then(() => console.log("client removed"));

        storage.removeItem('login:uid').then(() => console.log("uid removed"));

        storage.removeItem('login:logged_in').then(() => console.log("logged_in removed"));

    }

    return appReducer(state, action);

};


export default rootReducer;