import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {initializeHomePage} from "../actions";
import { Card, Image, Spinner, Navbar, OverlayTrigger, Popover, Alert} from "react-bootstrap";
import {StarFill} from 'react-bootstrap-icons';
import _ from "lodash";
import {isMobile, isDesktop, isTablet} from 'react-device-detect';
import TopHeader from "./TopHeader";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import BottomHeader from "./BottomHeader";
import { makeProductBrandNameBold } from "../helpers";
import { Link } from "react-router-dom";



class Landing extends Component {

    constructor(props) {

        super(props);

        const history = props.history;

        const params = props.match.params;

        const location = props.location;

        const width = window.innerWidth;

        const height = window.innerHeight;

        this.state = {
            history,
            params,
            location,
            width,
            height
        };


    }




    componentDidMount() {


        this.props.initializeHomePage();

    }


    renderPopOver(product){

        return(

            <Popover id={`popover-${product.id}`}>

                <Popover.Header as="h3">
                    {product.name}
                </Popover.Header>

                <Popover.Body style={{
                    whiteSpace: 'pre-line'
                }}>
                    { product.short_description.length >= 300 ? `${product.short_description}...` : product.short_description }
                </Popover.Body>

            </Popover>

        );

    }


    getOverlayPlacement(position, items_length){

        if(position % 3 === 0){

            return "left";

        }else if(position === items_length && items_length > 3){

            return "left";

        }else{

            return "right";

        }

    }



    renderQuantity(product){

        let quantity = product.quantity;

        if(/^[0-9]\d*$/.test(quantity)){

            // Quantity is a natural number: 0, 1, 2, etc.

            quantity = parseInt(quantity);

            if(quantity === 0){

                return(

                    <p
                        style={{
                            color: '#d20505'
                        }}
                    >
                        Item is out of stock
                    </p>

                );

            }else{

                return(

                    <p
                        style={{
                            fontWeight: 'bold'
                        }}
                    >
                        Qty: {quantity}
                    </p>

                );

            }

        }

    }


    renderTopicMenu(topic){

        const products = topic.products;

        const responsive = {
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 3,
                slidesToSlide: 3 // optional, default to 1.
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2,
                slidesToSlide: 2 // optional, default to 1.
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
                slidesToSlide: 1 // optional, default to 1.
            }
        };



        return(


            <Carousel
                key={topic.id}
                responsive={responsive}
                removeArrowOnDeviceType={["tablet", "mobile"]}
            >

                {products.map((product, index) => {

                    const position = index + 1;


                    return(
                        <OverlayTrigger
                            key={product.id}
                            trigger={'hover' || 'focus'}
                            placement={this.getOverlayPlacement(position, products.length)}
                            overlay={this.renderPopOver(product)}
                        >

                            <Link
                                to={`/product/product_id=${product.id}`}
                                style={{
                                    color: '#000000',
                                    textDecoration: 'none'
                                }}
                            >

                                <Card
                                    style={{
                                        margin: isMobile ? '10px' : '15px'
                                    }}
                                >


                                    <Image
                                        src={product.main_picture_url}
                                        id="landing-page-product-image"
                                        style={isMobile ? {
                                            width: '300px',
                                            height: '200px'
                                        } : {
                                            width: '400px',
                                            height: '300px'
                                        }}
                                    />

                                    <Card.Footer
                                        style={{
                                            height: '100px',
                                            paddingBottom: '30px'
                                        }}>

                                        {makeProductBrandNameBold(product.short_name, product.brand_name)}

                                        {this.renderQuantity(product)}

                                    </Card.Footer>


                                </Card>


                            </Link>



                        </OverlayTrigger>

                    )

                })}


            </Carousel>

        );

    }

    renderTopicsList(){

        const { topics } = this.props;

        return _.map(topics, topic => {

            return(

                <Card
                    key={topic.id}
                    style={{
                        marginBottom: '2rem',
                        width: isMobile ? this.state.width - 25 : this.state.width / 1.1
                    }}
                >

                    <Card.Header style={{
                        fontSize: '20px',
                        backgroundColor: '#AC0A0A',
                        color: '#fff',
                        fontWeight: 'bold',
                        fontFamily: 'Gill Sans, sans-serif'
                    }}>
                        {topic.name}
                    </Card.Header>

                    <Card.Body
                    >

                        {this.renderTopicMenu(topic)}

                    </Card.Body>

                </Card>

            );

        });


    }



    renderTopics(){

        const { topics } = this.props;

        if(topics !== null && topics !== undefined && !_.isEmpty((topics))){

            return(

                <div style={{
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    marginBottom: '2rem'
                }}>

                    {this.renderTopicsList()}

                </div>

            );

        }

    }


    renderLandingImages(){

        const { landing_images } = this.props;

        if(landing_images.length > 0){

            const responsive = {
                desktop: {
                    breakpoint: { max: 3000, min: 1024 },
                    items: 1,
                    slidesToSlide: 1 // optional, default to 1.
                },
                tablet: {
                    breakpoint: { max: 1024, min: 464 },
                    items: 1,
                    slidesToSlide: 1 // optional, default to 1.
                },
                mobile: {
                    breakpoint: { max: 464, min: 0 },
                    items: 1,
                    slidesToSlide: 1 // optional, default to 1.
                }
            };

            return(



                <Carousel
                    key="landing-images-carousel"
                    responsive={responsive}
                    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                    autoPlay={true}
                    autoPlaySpeed={5000}
                    swipeable={false}
                    draggable={false}
                    infinite={true}
                    transitionDuration={500}
                >

                    {landing_images.map((image, index) => {

                        return(

                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: '6rem',
                                width: '100%',
                                height: '100%'
                            }}>

                                <Image

                                    key={index}
                                    className="landing-image"
                                    src={image.url}
                                    style={{
                                        width: isMobile ? this.state.width - 25 : this.state.width / 1.1,
                                        height:  isMobile ? '150px' : '200px'
                                    }}
                                />

                            </div>

                        )

                    })}


                </Carousel>






            );


        }




    }


    renderBody(){

        const { initializing_page, initializing_user_page} = this.props;


        if(initializing_page || initializing_user_page){

            return(

                <div
                    style={{
                        backgroundColor: '#f7f1eb'
                    }}
                >

                    <Spinner animation="border" variant="primary" className="spinner" />

                </div>

            );

        }else{

            return(

                <div
                    style={{
                        backgroundColor: '#f7f1eb'
                    }}
                >

                    <TopHeader
                        history={this.state.history}
                        params={this.state.params}
                        location={this.state.location}
                    />



                    {this.renderLandingImages()}


                    {this.renderTopics()}

                    <BottomHeader
                        history={this.state.history}
                        params={this.state.params}
                        location={this.state.location}
                    />



                </div>

            )
        }

    }



    render() {



        return (

            <Fragment>

                {this.renderBody()}

            </Fragment>

        );

    }


}


const mapStateToProps = (state) => {

    const {
        initializing_page,
        topics,
        landing_images
    } = state.landing;

    const {
        initializing_user_page,
        header_categories
    } = state.user_pages;


    return {
        initializing_page,
        topics,
        initializing_user_page,
        header_categories,
        landing_images
    };


};


export default connect(mapStateToProps, {
    initializeHomePage
})(Landing);