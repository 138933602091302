import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import _ from "lodash";
import {isMobile, isDesktop, isTablet} from 'react-device-detect'
import TopHeader from "./TopHeader";
import {Spinner, Card, Image, Button, OverlayTrigger, Popover, Breadcrumb, ButtonGroup} from "react-bootstrap";
import {
    fetchCategoryData,
    clearCategoryPageState,
    exitCategoryPage
} from "../actions";
import BottomHeader from "./BottomHeader";
import {Helmet} from "react-helmet";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { makeProductBrandNameBold } from "../helpers";
import { Link } from "react-router-dom";
import {ScrollTop} from "./ScrollTop";



class Category extends Component{

    constructor(props){

        super(props);

        const history = props.history;

        const params = props.match.params;

        const location = props.location;

        const width = window.innerWidth;

        const height = window.innerHeight;

        const category_id = parseInt(params.category_id);

        this.state = {
            history,
            params,
            location,
            width,
            height,
            category_id
        };

    }



    componentDidUpdate(prevProps){

        const { history, category_id } = this.state;

        const {fetchCategoryData} = this.props;

        const current_category_id = parseInt(category_id);

        const new_category_id = parseInt(history.location.pathname.split("=")[1]);

        if(current_category_id !== new_category_id && !_.isNaN(current_category_id) && !_.isNaN(new_category_id) ){

            console.log("reinitialize category page");

            this.setState({category_id: new_category_id});

            fetchCategoryData(new_category_id, history);


        }



    }

    componentWillUnmount(){

        this.props.exitCategoryPage(window.pageXOffset, window.pageYOffset);

    }



    componentDidMount() {


        const {fetchCategoryData, previous_category_id, xoffset, yoffset} = this.props;

        const { history , category_id} = this.state;


        if(category_id !== previous_category_id){

            fetchCategoryData(category_id, history);

        }else{

            if(xoffset !== null && yoffset !== null){

                window.scrollTo(xoffset, yoffset);

            }




        }








    }

    getProducts(){

        let products = this.props.products;

        if(products.length % 3 === 0) {

            return products;

        }else{

            let nearest_multiple_3 = products.length;

            while(nearest_multiple_3 % 3 !== 0){

                products.push({});

                nearest_multiple_3 += 1;


            }

            return products;

        }



    }


    renderPopOver(product){

        return(

            <Popover id={`popover-${product.id}`}>

                <Popover.Header as="h3">
                    {product.name}
                </Popover.Header>

                <Popover.Body style={{
                    whiteSpace: 'pre-line'
                }}>
                    { product.short_description.length >= 300 ? `${product.short_description}...` : product.short_description }
                </Popover.Body>

            </Popover>

        );

    }

    renderQuantity(product){

        let quantity = product.quantity;

        if(/^[0-9]\d*$/.test(quantity)){

            // Quantity is a natural number: 0, 1, 2, etc.

            quantity = parseInt(quantity);

            if(quantity === 0){

                return(

                    <p
                        style={{
                            color: '#d20505'
                        }}
                    >
                        Item is out of stock
                    </p>

                );

            }else{

                return(

                    <p
                        style={{
                            fontWeight: 'bold'
                        }}
                    >
                        Qty: {quantity}
                    </p>

                );

            }

        }

    }

    renderProductsList(){

        const products = isMobile ? this.props.products : this.getProducts();

        return _.map(products, (product, index) => {

            const position = index + 1;


            if(_.isEmpty(product)){

                return(

                    <Card
                        key={index}
                        style={{
                            flexBasis: this.state.width / 4,
                            margin: '15px',
                            visibility: 'hidden'
                        }}
                    />


                );


            }else{

                return(


                    <OverlayTrigger
                        key={product.id}
                        trigger={'hover' || 'focus'}
                        placement={ position % 3 === 0 ? 'left' : 'right'}
                        overlay={this.renderPopOver(product)}
                    >

                        <Link
                            to={`/product/product_id=${product.id}`}
                            style={{
                                color: '#000000',
                                textDecoration: 'none'
                            }}
                        >

                            <Card
                                style={
                                    isMobile ?
                                        {
                                            width: this.state.width - 35,
                                            margin: '15px'
                                        } :
                                        {
                                            width: this.state.width / 4,
                                            margin: '15px'
                                        }}
                            >

                                <Card.Img variant="top" className="product-image" src={product.main_picture_url} />

                                <Card.Footer style={{
                                    height: '100px',
                                    paddingBottom: '30px'
                                }}>

                                    {makeProductBrandNameBold(product.short_name, product.brand_name)}

                                    {this.renderQuantity(product)}

                                </Card.Footer>


                            </Card>

                        </Link>








                    </OverlayTrigger>




                );



            }



        });


    }





    parentCategories(){

        const { parent_categories } = this.props;

        const { history } = this.state;

        return _.map(parent_categories, (parent_category, index) => {

            return(

                <Breadcrumb.Item
                    key={index}
                    onClick={() => {
                        history.push(`/category/category_id=${parent_category.id}`);
                    }}
                >
                    {parent_category.name}
                </Breadcrumb.Item>

            );

        });


    }


    topParentCategories(){

        const { parent_categories, category_name } = this.props;

        if(parent_categories.length > 0){

            return(

                <Breadcrumb
                    style={{
                        marginLeft: isMobile ? '1.5rem' : '2rem'
                    }}
                >

                    {this.parentCategories()}

                    <Breadcrumb.Item active>
                        {category_name}
                    </Breadcrumb.Item>

                </Breadcrumb>


            );

        }

    }


    getSubcategories(){

        let subcategories = this.props.subcategories;

        if(subcategories.length % 3 === 0) {

            return subcategories;

        }else{

            let nearest_multiple_3 = subcategories.length;

            while(nearest_multiple_3 % 3 !== 0){

                subcategories.push({});

                nearest_multiple_3 += 1;


            }

            return subcategories;

        }



    }

    subCategoriesList(){

        const { history } = this.state;

        let subcategories = isMobile ? this.props.subcategories : this.getSubcategories();

        return _.map(subcategories, (subcategory, index) => {


            if(_.isEmpty(subcategory)){

                return(

                    <Card
                        key={index}
                        style={{
                            flexBasis: this.state.width / 4,
                            margin: '15px',
                            visibility: 'hidden'
                        }}
                    />


                );


            }else{

                return(


                    <Card
                        key={index}
                        style={
                            isMobile ?
                                {
                                    width: this.state.width - 40,
                                    margin: '15px',
                                    height: this.state.height / 4
                                } :
                                {
                                    flexBasis: this.state.width / 4,
                                    margin: '15px',
                                    width: this.state.width / 3,
                                    height: '200px'
                                }}
                    >

                        <Card.Body
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column'
                            }}
                        >

                            <Card.Title
                                style={{
                                    marginBottom: '2rem',
                                    fontSize:  '30px'
                                }}
                            >
                                {subcategory.name}
                            </Card.Title>


                            <Button
                                variant="success"
                                onClick={() => {
                                    history.push(`/category/category_id=${subcategory.id}`);
                                }}
                                style={{
                                    fontSize: isMobile ? '18px' : '20px'
                                }}
                            >
                                View Category
                            </Button>

                        </Card.Body>

                    </Card>




                );



            }



        });







    }




    renderSubcategories(){

        const { subcategories, products } = this.props;

        const { history } = this.state;

        if(subcategories.length > 0){

            if(isMobile){

                const responsive = {
                    desktop: {
                        breakpoint: { max: 3000, min: 1024 },
                        items: 5,
                        slidesToSlide: 5 // optional, default to 1.
                    },
                    tablet: {
                        breakpoint: { max: 1024, min: 464 },
                        items: 2,
                        slidesToSlide: 2 // optional, default to 1.
                    },
                    mobile: {
                        breakpoint: { max: 464, min: 0 },
                        items: 1,
                        slidesToSlide: 1 // optional, default to 1.
                    }
                };

                return(

                    <Card
                        style={{
                            marginBottom: '2rem',
                            width: this.state.width / 1.1,
                            border: 'None'
                        }}
                    >


                        <Card.Body>

                            <Carousel
                                responsive={responsive}
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                            >

                                {subcategories.map((subcategory, index) => {


                                    return(


                                        <Link
                                            to={`/category/category_id=${subcategory.id}`}
                                            style={{
                                                textDecoration: 'none',
                                                color: '#000000'
                                            }}
                                            key={index}
                                        >

                                            <Card
                                                style={ isMobile ? {
                                                    marginRight: '10px',
                                                    marginLeft: '10px'
                                                } :  {
                                                    margin: '15px',
                                                }}
                                            >

                                                <Card.Body>

                                                    <Card.Title style={{
                                                        textAlign: 'center',
                                                        fontSize: isMobile ? '16px' : '18px'
                                                    }}>
                                                        {subcategory.name}
                                                    </Card.Title>

                                                </Card.Body>

                                            </Card>

                                        </Link>




                                    )

                                })}


                            </Carousel>


                        </Card.Body>

                    </Card>

                );

            }else{

                return(

                    <div style={{
                        width: '10%',
                        overflowY: 'scroll',
                        height: this.state.height / 2 ,
                        position: 'sticky',
                        top: '200px',
                        marginLeft: '10px',
                        paddingRight: '10px'
                    }}>

                        {subcategories.map((subcategory, index) => {


                            return(


                                <Link
                                    to={`/category/category_id=${subcategory.id}`}
                                    style={{
                                        textDecoration: 'none',
                                        color: '#000000'
                                    }}
                                    key={index}
                                >

                                    <Card
                                        style={ isMobile ? {
                                            marginRight: '10px',
                                            marginLeft: '10px'
                                        } :  {
                                            margin: '15px',
                                        }}
                                    >

                                        <Card.Body>

                                            <Card.Title style={{
                                                textAlign: 'center',
                                                fontSize: isMobile ? '16px' : '18px'
                                            }}>
                                                {subcategory.name}
                                            </Card.Title>

                                        </Card.Body>

                                    </Card>

                                </Link>




                            )

                        })}




                    </div>


                );

            }




        }

    }

    renderTemplate(){

        const { subcategories, products } = this.props;

        const { history } = this.state;
        
            if(products.length === 0 ){

                return(

                    <div style={ isMobile ? {
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'center',
                        flexDirection: 'column',
                        marginBottom: '3rem'
                    } : {
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'center',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        marginBottom: '4rem'
                    }}>

                        {this.subCategoriesList()}

                    </div>


                )

            }else{





                if(isMobile){


                    return(


                        <div>

                            <div style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}>


                                {this.renderSubcategories()}


                            </div>

                            <div style={{
                                display: 'flex',
                                flex: 1,
                                justifyContent: 'center',
                                flexDirection: 'column',
                                marginBottom: '3rem'
                            }}>

                                {this.renderProductsList()}

                            </div>

                        </div>



                    );


                }else{

                    return(


                        <div
                            style={subcategories.length > 0 ? {
                                display: 'flex',
                                flexDirection: 'row'
                            } : {} }
                        >

                            {this.renderSubcategories()}


                            <div style={{
                                width: subcategories.length > 0 ? '90%' : '100%',
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                marginBottom: '4rem',
                                justifyContent: 'center'
                            }}>

                                {this.renderProductsList()}


                            </div>



                        </div>

                    );

                }



            }





    }

    renderBody(){

        const { fetching_category_data, initializing_user_page, category_name } = this.props;

        if(fetching_category_data || initializing_user_page){

            return(

                <div
                    style={{
                        backgroundColor: '#f7f1eb'
                    }}
                >

                    <Spinner animation="border" variant="primary" className="spinner" />

                </div>

            );

        }else{

            return(

                <div
                    style={{
                        backgroundColor: '#f7f1eb'
                    }}
                >

                    <Helmet>

                        <title>{category_name}</title>

                        <meta
                            name="description"
                            content={`Bodega Liquidation: ${category_name}`}
                        />

                    </Helmet>

                    <TopHeader
                        history={this.state.history}
                        params={this.state.params}
                        location={this.state.location}
                    />

                    {this.topParentCategories()}



                    <p style={{
                        fontSize: isMobile ? '25px' : '40px',
                        marginBottom: '1.5rem',
                        textAlign: 'center'
                    }}>
                        {category_name}
                    </p>



                    {this.renderTemplate()}



                    <BottomHeader
                        history={this.state.history}
                        params={this.state.params}
                        location={this.state.location}
                    />


                </div>

            )
        }



    }


    render() {


        return (

            <Fragment>

                {this.renderBody()}

                <ScrollTop/>

            </Fragment>

        );

    }



}

const mapStateToProps = (state) => {

    const {
        initializing_user_page
    } = state.user_pages;

    const {
        fetching_category_data,
        category_name,
        products,
        parent_categories,
        subcategories,
        previous_category_id,
        xoffset,
        yoffset
    } = state.category;


    return {
        initializing_user_page,
        fetching_category_data,
        products,
        category_name,
        parent_categories,
        subcategories,
        previous_category_id,
        xoffset,
        yoffset
    };


};

export default connect(mapStateToProps, {
    fetchCategoryData,
    clearCategoryPageState,
    exitCategoryPage
})(Category);