import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import _ from "lodash";
import {isMobile, isDesktop, isTablet} from 'react-device-detect'
import TopHeader from "./TopHeader";
import {Spinner, Card, Image, Button, OverlayTrigger, Popover, Breadcrumb, ButtonGroup, Form} from "react-bootstrap";
import {
    resetPassword,
    clearResetPasswordState
} from "../actions";
import BottomHeader from "./BottomHeader";
import {Helmet} from "react-helmet";

class ResetPassword extends Component{

    constructor(props){

        super(props);

        const history = props.history;

        const params = props.match.params;

        const location = props.location;

        const width = window.innerWidth;

        const height = window.innerHeight;

        const password = "";

        const password_confirmation = "";

        const access_token = params.access_token;

        const client = params.client;

        const uid = params.uid;

        const reset_password_token = params.reset_password_token;

        this.state = {
            history,
            params,
            location,
            width,
            height,
            password,
            password_confirmation,
            access_token,
            client,
            uid,
            reset_password_token
        };

    }

    componentWillUnmount(){

        this.props.clearResetPasswordState();

    }


    formError(){

        const { error } = this.props;

        if(error.length > 0){

            return(

                <p
                    style={{
                        color: '#ff0000',
                        marginBottom: '30px'
                    }}
                >
                    {error}
                </p>

            );


        }

    }


    renderSubmitButton(){

        const { loading, resetPassword } = this.props;

        const { history, access_token, client, uid, reset_password_token, password, password_confirmation } = this.state;

        if(loading){

            return(

                <div style={{
                    marginBottom: '10px'
                }}>

                    <Spinner animation="border" variant="primary" />

                </div>

            );

        }else{

            return(

                <Button
                    variant="outline-success"
                    style={{
                        width: isMobile ? this.state.width - 100 : '40%',
                        marginBottom: '30px',
                        borderRadius: '10px'
                    }}
                    onClick={() => {
                        resetPassword(history, access_token, client, uid, reset_password_token, password, password_confirmation);
                    }}
                >
                    Submit
                </Button>

            );

        }

    }

    renderBody(){

        const {
            initializing_user_page
        } = this.props;

        const { password, password_confirmation } = this.state;

        if(initializing_user_page){

            return(

                <div
                    style={{
                        backgroundColor: '#f7f1eb'
                    }}
                >

                    <Spinner animation="border" variant="primary" className="spinner" />

                </div>

            );

        }else{

            return(

                <div style={{
                    backgroundColor: '#f7f1eb'
                }}>

                    <Helmet>

                        <title>Reset Password</title>

                    </Helmet>

                    <TopHeader
                        history={this.state.history}
                        params={this.state.params}
                        location={this.state.location}
                    />


                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: '4rem'
                        }}
                    >

                        <Card style={{
                            width: isMobile ?  this.state.width - 50 :  this.state.width / 2,
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}>

                            <Card.Body
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    marginBottom: '2rem',
                                    marginTop: '2rem'
                                }}
                            >

                                <p style={{
                                    fontSize: isMobile ? '20px' : '30px',
                                    marginBottom: isMobile ? '2rem' : '3rem',
                                    textAlign: 'center'
                                }}>
                                    Reset Password
                                </p>


                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                    }}
                                    style={{
                                        marginBottom: '1rem'
                                    }}
                                >

                                    <Form.Group
                                        style={{
                                            width: isMobile ? this.state.width - 100 : this.state.width / 3,
                                            marginBottom: '30px'
                                        }}
                                    >

                                        <Form.Control
                                            type="password"
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => {
                                                this.setState({password: e.target.value});
                                            }}
                                        />

                                    </Form.Group>

                                    <Form.Group
                                        style={{
                                            width: isMobile ? this.state.width - 100 : this.state.width / 3,
                                            marginBottom: '30px'
                                        }}
                                    >

                                        <Form.Control
                                            type="password"
                                            placeholder="Re-type password"
                                            value={password_confirmation}
                                            onChange={(e) => {
                                                this.setState({password_confirmation: e.target.value});
                                            }}
                                        />

                                    </Form.Group>

                                </Form>

                                {this.formError()}

                                {this.renderSubmitButton()}

                            </Card.Body>


                        </Card>

                    </div>



                    <BottomHeader
                        history={this.state.history}
                        params={this.state.params}
                        location={this.state.location}
                    />




                </div>

            )
        }



    }

    render() {


        return (

            <Fragment>

                {this.renderBody()}

            </Fragment>

        );

    }

}

const mapStateToProps = (state) => {

    const {
        initializing_user_page
    } = state.user_pages;

    const  {
        loading,
        error
    } = state.reset_password;


    return {
        initializing_user_page,
        loading,
        error
    };


};

export default connect(mapStateToProps, {
    resetPassword,
    clearResetPasswordState
})(ResetPassword);