import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { Button } from "react-bootstrap";
import _ from "lodash";
import {isMobile, isDesktop, isTablet} from 'react-device-detect';
import TopHeader from "./TopHeader";
import BottomHeader from "./BottomHeader";

class NotFound extends Component{

    constructor(props) {

        super(props);

        const history = props.history;

        const params = props.match.params;

        const location = props.location;

        const width = window.innerWidth;

        const height = window.innerHeight;

        this.state = {
            history,
            params,
            location,
            width,
            height
        };

    }

    render() {


        return (

            <div style={{
                backgroundColor: '#f7f1eb'
            }}>

                <TopHeader
                    history={this.state.history}
                    params={this.state.params}
                    location={this.state.location}
                />



                <div style={ isMobile ? {
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    marginBottom: '2rem',
                    height: '25vh'
                } : {
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    marginBottom: '2rem',
                    height: '50vh'
                }}>

                    <p style={ isMobile ? {
                        marginRight: '25px',
                        marginLeft: '20px',
                        fontSize: '18px',
                        marginBottom: '2rem'
                    }: {
                        fontSize: '25px',
                        marginBottom: '3rem'
                    }}>
                        The page you were looking for was not found. Try searching on Bodega Liquidation homepage.
                    </p>


                    <Button
                        style={ isMobile ? {
                            borderRadius: '20px',
                            width: '50%'
                        } :  {
                            fontSize: '20px',
                            borderRadius: '20px',
                            width: '10%'
                        }}
                        variant="success"
                        onClick={() => {
                            this.state.history.push("/");
                        }}
                    >
                        Homepage
                    </Button>



                </div>




                <BottomHeader
                    history={this.state.history}
                    params={this.state.params}
                    location={this.state.location}
                />

            </div>

        );

    }


}

export default NotFound;