import {
    INITIALIZE_LANDING_PAGE,
    INITIALIZE_LANDING_PAGE_COMPLETE
} from "../actions/types"

const INITIAL_STATE = {
    initializing_page: false,
    topics: [],
    landing_images: []
};


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case INITIALIZE_LANDING_PAGE_COMPLETE:
            return{
                ...state,
                initializing_page: false,
                topics: action.payload.topics,
                landing_images: action.payload.landing_images
            };
        case INITIALIZE_LANDING_PAGE:
            return{
                ...state,
                initializing_page: true
            };
        default:
            return state;
    }
};