import {
    PROFILE_PAGE_ROUTE,
    INITIALIZE_PROFILE_PAGE,
    INITIALIZE_PROFILE_PAGE_COMPLETE,
    CLEAR_PROFILE_PAGE_STATE,
    LOGOUT_SUCCESS,
    OPEN_CUSTOMER_CHANGE_EMAIL_MODAL,
    CLOSE_CUSTOMER_CHANGE_EMAIL_MODAL,
    CUSTOMER_CHANGE_EMAIL_ROUTE,
    CUSTOMER_CHANGE_EMAIL,
    CUSTOMER_CHANGE_EMAIL_COMPLETE,
    CUSTOMER_CHANGE_EMAIL_SUCCESS,
    CUSTOMER_CHANGE_EMAIL_FAILURE,
    CUSTOMER_UID_CHANGED,
    NEW_CUSTOMER_EMAIL_CHANGED
} from "./types";

import axios from "axios";
import { getFormData } from "../helpers";
import _ from "lodash";

export const newCustomerEmailChanged = (new_email) => {

    return{
      type: NEW_CUSTOMER_EMAIL_CHANGED,
      payload: new_email
    };

};

export const changeCustomerEmail = (
    access_token,
    client,
    uid,
    history,
    new_email
) => {

    return(dispatch) => {

        dispatch({type: CUSTOMER_CHANGE_EMAIL});

        const config = {
            headers: {
                "Accept": "application/json",
                "access_token": access_token,
                "client": client,
                "uid": uid
            }
        };

        let bodyFormData = getFormData({
            email: new_email
        });

        axios.post(CUSTOMER_CHANGE_EMAIL_ROUTE, bodyFormData, config)
            .then(response => {

               dispatch({type: CUSTOMER_CHANGE_EMAIL_COMPLETE});

               const data = response.data;

               console.log(data);

               const success = data.success;

               if(success){

                   const uid = data.uid;

                   const email = data.email;
                   

                   dispatch({type: CUSTOMER_CHANGE_EMAIL_SUCCESS, payload: {
                       change_email_modal_success_message: 'Successfully changed email',
                       email: email
                   }});

                   dispatch({type: CUSTOMER_UID_CHANGED, payload: uid});


               }else{

                   const message = data.message;

                   dispatch({type: CUSTOMER_CHANGE_EMAIL_FAILURE, payload: message});

               }


            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });

    };

};

export const closeCustomerChangeEmailModal = () => {

    return{
      type: CLOSE_CUSTOMER_CHANGE_EMAIL_MODAL
    };

};

export const openCustomerChangeEmailModal = () => {

    return{
      type: OPEN_CUSTOMER_CHANGE_EMAIL_MODAL
    };

};

export const clearProfilePageState = () => {

    return{
      type: CLEAR_PROFILE_PAGE_STATE
    };

};

export const initializeProfilePage = (history, access_token, client, uid) => {

    return(dispatch) => {

        dispatch({type: INITIALIZE_PROFILE_PAGE});

        const config = {
            headers: {
                "Accept": "application/json",
                "access_token": access_token,
                "client": client,
                "uid": uid
            }
        };

        axios.get(PROFILE_PAGE_ROUTE, config)
            .then(response => {

                const data = response.data;

                console.log(data);

                const full_name = data.full_name;

                const email = data.email;

                const phone_number_verified = data.phone_number_verified;

                const phone_number = data.phone_number;

                const account_approved = data.account_approved;

                const company_address = data.company_address;

                const company_city = data.company_city;

                const company_name = data.company_name;

                const company_state = data.company_state;


                const company_zip_code = data.company_zip_code;


                dispatch({type: INITIALIZE_PROFILE_PAGE_COMPLETE, payload: {
                    full_name: full_name,
                    email: email,
                    phone_number_verified: phone_number_verified,
                    phone_number: phone_number,
                    account_approved: account_approved,
                    company_address: company_address,
                    company_city: company_city,
                    company_name: company_name,
                    company_state: company_state,
                    company_zip_code: company_zip_code
                }});


            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });


    };

};