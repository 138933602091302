import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {initializeHomePage} from "../actions";
import { Card, Image, Spinner, Navbar} from "react-bootstrap";
import {List as HamburgerIcon, ChevronRight, Facebook, Instagram} from 'react-bootstrap-icons';
import _ from "lodash";
import {isMobile, isDesktop, isTablet} from 'react-device-detect';
import TopHeader from "./TopHeader";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

class BottomHeader extends Component{

    constructor(props) {

        super(props);

        const history = this.props.history;

        const params = this.props.params;

        const location = this.props.location;

        const width = window.innerWidth;

        const height = window.innerHeight;

        this.state = {
            history,
            params,
            location,
            width,
            height
        };


    }


    render(){

        return(


           <Fragment>

               <Image
                   className="rotated-img"
                   src={require("../images/fading.png")}
                   style={{
                       width: '100%',
                       height: '40px',
                       marginTop: 0,
                       marginLeft: 0,
                       marginRight: 0
                   }}
               />

               <Navbar
                   style={ isMobile ? {} :  {height: '500px'}}
                   className="bottom-navbar"
               >

                   <div style={ isMobile ? {
                       display: 'flex',
                       flex: 1,
                       flexDirection: 'column',
                       justifyContent: 'center',
                       alignItems: 'center',
                       marginBottom: '50px',
                       marginTop: '50px'
                   }:  {
                       display: 'flex',
                       flex: 1,
                       flexDirection: 'row',
                       justifyContent: 'center',
                       alignItems: 'center'
                   }}>


                       <div style={ isMobile ? {
                           display: 'flex',
                           justifyContent: 'center',
                           flexDirection: 'column',
                           alignItems: 'center',
                           marginLeft: '20px',
                           marginRight: '20px',
                           marginBottom: '40px'
                       } : {
                           display: 'flex',
                           justifyContent: 'center',
                           flexDirection: 'column',
                           alignItems: 'center',
                           marginRight: '8rem'
                       }}>

                           <p
                               className="bottom-navbar-text"
                               style={isMobile ? {
                                   textAlign: 'center'
                               } : {}}
                           >
                               Address: 7700 Kempwood Drive, Houston, TX 77055
                           </p>

                           <p
                               className="bottom-navbar-text"
                               style={isMobile ? {
                                   textAlign: 'center'
                               } : {}}>
                               Store hours: Monday-Saturday: 10am - 7pm, Sunday: 11am - 5pm
                           </p>


                           <p
                               className="bottom-navbar-text"
                               style={isMobile ? {
                                   textAlign: 'center'
                               } : {}}>
                               Wholesale hours: Monday-Friday: 8am - 5pm, Saturday: 8am - 1pm
                           </p>


                           <p
                               className="bottom-navbar-text"
                               style={isMobile ? {
                                   textAlign: 'center'
                               } : {}}>
                               Store Telephone: (832) 831-0196
                           </p>

                           <p
                               className="bottom-navbar-text"
                               style={isMobile ? {
                                   textAlign: 'center'
                               } : {}}>
                               Wholesale Telephone: (713) 294-0909
                           </p>


                           <p
                               className="bottom-navbar-text"
                               style={isMobile ? {
                                   textAlign: 'center'
                               } : {}}>
                               Follow us on:
                           </p>


                           <div style={{
                               display: 'flex',
                               flexDirection: 'row',
                               justifyContent: 'space-between'
                           }}>

                               <Facebook
                                   style={{
                                       marginRight: '25px',
                                       color: '#fff'
                                   }}
                                   size={30}
                                   onClick={() => {
                                       window.open("https://www.facebook.com/bodegaliquidationwholesale/");
                                   }}
                               />

                               <Instagram
                                   size={30}
                                   style={{
                                       color: '#fff'
                                   }}
                                   onClick={() => {
                                       window.open("https://instagram.com/bodegaliquidation?igshid=YmMyMTA2M2Y=");
                                   }}
                               />

                           </div>


                       </div>


                       <div>


                           <iframe
                               src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3461.623898629629!2d-95.48789218438719!3d29.817408181965497!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c5d255b89761%3A0x1aad5deeabbfc152!2sBodega%20Liquidation!5e0!3m2!1sen!2sus!4v1660659808977!5m2!1sen!2sus"
                               width={ isMobile ? this.state.width - 50 : '600' }
                               height={isMobile ? this.state.width - 100: '350'}
                               style={{
                                   border: '0'
                               }}
                               allowFullScreen
                               referrerPolicy="no-referrer-when-downgrade"
                           >
                           </iframe>


                       </div>



                   </div>

               </Navbar>

           </Fragment>

        );

    }

}

export default BottomHeader;