import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import _ from "lodash";
import {isMobile, isDesktop, isTablet} from 'react-device-detect'
import TopHeader from "./TopHeader";
import {Spinner, Card, Image, Button, OverlayTrigger, Popover, Breadcrumb, ButtonGroup, Form, Modal} from "react-bootstrap";
import {
    initializeProfilePage,
    clearProfilePageState,
    openCustomerChangeEmailModal,
    closeCustomerChangeEmailModal,
    changeCustomerEmail,
    newCustomerEmailChanged
} from "../actions";
import BottomHeader from "./BottomHeader";
import {Helmet} from "react-helmet";

class Profile extends Component{

    constructor(props){

        super(props);

        const history = props.history;

        const params = props.match.params;

        const location = props.location;

        const width = window.innerWidth;

        const height = window.innerHeight;

        this.state = {
            history,
            params,
            location,
            width,
            height
        };

    }

    componentDidMount(){

        const { history } = this.state;

        const { logged_in, initializeProfilePage, access_token, client, uid } = this.props;

        if(!logged_in){

            history.push("/");

        }else{

            initializeProfilePage(history, access_token, client, uid);

        }


    }


    componentWillUnmount(){

        this.props.clearProfilePageState();

    }


    renderPhoneNumber(){

        const {phone_number, phone_number_verified } = this.props;
        //
        const { history } = this.state;


        if(!phone_number_verified){

            return(

                <span
                    id="profile-form-link"
                    onClick={() => {
                        history.push("/phone-number");
                    }}
                >
                   Add Phone Number
                </span>

            );

        }else{

            return(



                <Form.Group
                >

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'row'
                        }}
                    >
                        <Form.Label  >
                            Phone Number
                        </Form.Label>

                        <Button
                            id="edit-number-button"
                            variant="link"
                            onClick={() => {
                                history.push("/phone-number");
                            }}
                        >
                            Edit
                        </Button>

                    </div>


                    <Form.Control
                        readOnly
                        type="text"
                        value={phone_number}
                        disabled
                    />

                </Form.Group>



            )

        }


    }


    changeEmailModalBody(){

        const {
            changing_email,
            new_email,
            newCustomerEmailChanged
        } = this.props;

        if(changing_email){

            return(

                <div style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>

                    <Spinner animation="border" variant="primary" />

                </div>


            );

        }else{

            return(

                <Form onSubmit={(e) => e.preventDefault()}>

                    <Form.Group>


                        <Form.Control
                            type="email"
                            placeholder="Email"
                            value={new_email}
                            onChange={(e) => {
                                newCustomerEmailChanged(e.target.value);
                            }}
                        />

                    </Form.Group>




                </Form>


            );

        }

    }


    changeEmailModalMessage(){

        const {
            change_email_modal_success_message,
            change_email_modal_error_message
        } = this.props;

        if(change_email_modal_error_message){

            return(

                <p style={{
                    color: '#ff0000',
                    textAlign: 'center'
                }}>
                    {change_email_modal_error_message}
                </p>

            );

        }else{

            return(

                <p style={{
                    color: '#228B22',
                    textAlign: 'center'
                }}>
                    {change_email_modal_success_message}
                </p>

            );

        }

    }

    changeEmailModal(){

        const {
            change_email_modal_visible,
            closeCustomerChangeEmailModal,
            changeCustomerEmail,
            access_token,
            client,
            uid,
            new_email
        } = this.props;

        const {
            history
        } = this.state;

        if(change_email_modal_visible){

            return(

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={change_email_modal_visible}
                    onHide={() => {
                        closeCustomerChangeEmailModal();
                    }}
                >
                    <Modal.Header closeButton>

                        <Modal.Title id="contained-modal-title-vcenter">
                            Change Email
                        </Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        {this.changeEmailModalBody()}

                    </Modal.Body>

                    {this.changeEmailModalMessage()}

                    <Modal.Footer>

                        <Button
                            variant="success"
                            style={{
                                marginRight: '10px'
                            }}
                            onClick={() => {
                                changeCustomerEmail(
                                    access_token,
                                    client,
                                    uid,
                                    history,
                                    new_email
                                );
                            }}>
                            Update
                        </Button>

                        <Button
                            variant="secondary"
                            onClick={() => {
                                closeCustomerChangeEmailModal();
                            }}>
                            Close
                        </Button>

                    </Modal.Footer>

                </Modal>



            );

        }

    }

    renderBody(){

        const {
            initializing_user_page,
            initializing_page,
            full_name,
            email,
            account_approved,
            company_address,
            company_city,
            company_name,
            company_state,
            company_zip_code,
            openCustomerChangeEmailModal
        } = this.props;

        if(initializing_page || initializing_user_page){

            return(

                <div
                    style={{
                        backgroundColor: '#f7f1eb'
                    }}
                >

                    <Spinner animation="border" variant="primary" className="spinner" />

                </div>

            );

        }else{

            return(

                <div style={{
                    backgroundColor: '#f7f1eb'
                }}>

                    <Helmet>

                        <title>Profile</title>

                    </Helmet>

                    <TopHeader
                        history={this.state.history}
                        params={this.state.params}
                        location={this.state.location}
                    />


                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: '4rem',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >

                        <p style={{
                            fontSize: '30px',
                            marginBottom: isMobile ? '2rem' : '3rem',
                            textAlign: 'center'
                        }}>
                            Profile
                        </p>

                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}
                            style={{
                                marginBottom: '1rem',
                                width: isMobile ? this.state.width - 50:  this.state.width / 2
                            }}
                        >

                            <Form.Group
                                id="profile-input-container"
                            >

                                <Form.Label  >
                                    Account Status
                                </Form.Label>


                                <Form.Control
                                    readOnly
                                    type="text"
                                    value={account_approved ? 'Approved' : 'Unapproved'}
                                    disabled
                                />

                            </Form.Group>



                            <Form.Group
                                id="profile-input-container"
                            >

                                <Form.Label  >
                                    Full Name
                                </Form.Label>


                                <Form.Control
                                    readOnly
                                    type="text"
                                    value={full_name}
                                    disabled
                                />

                            </Form.Group>


                            <Form.Group
                                id="profile-input-container"
                            >
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }}>

                                    <Form.Label  >
                                        Email
                                    </Form.Label>


                                    <span
                                        style={{
                                            color: 'blue',
                                            textDecoration: 'underline'
                                        }}
                                        onClick={() => {
                                            openCustomerChangeEmailModal();
                                        }}
                                    >
                                        Edit
                                    </span>

                                </div>



                                <Form.Control
                                    readOnly
                                    type="text"
                                    value={email}
                                    disabled
                                />

                            </Form.Group>

                            <Form.Group
                                id="profile-input-container"
                            >

                                <Form.Label  >
                                    Company Name
                                </Form.Label>


                                <Form.Control
                                    readOnly
                                    type="text"
                                    value={company_name}
                                    disabled
                                />

                            </Form.Group>


                            <Form.Group
                                id="profile-input-container"
                            >

                                <Form.Label  >
                                    Address
                                </Form.Label>


                                <Form.Control
                                    readOnly
                                    type="text"
                                    value={company_address}
                                    disabled
                                />

                            </Form.Group>


                            <Form.Group
                                id="profile-input-container"
                            >

                                <Form.Label  >
                                    State
                                </Form.Label>


                                <Form.Control
                                    readOnly
                                    type="text"
                                    value={company_state}
                                    disabled
                                />

                            </Form.Group>

                            <Form.Group
                                id="profile-input-container"
                            >

                                <Form.Label  >
                                    City
                                </Form.Label>


                                <Form.Control
                                    readOnly
                                    type="text"
                                    value={company_city}
                                    disabled
                                />

                            </Form.Group>

                            <Form.Group
                                id="profile-input-container"
                            >

                                <Form.Label  >
                                    Zip Code
                                </Form.Label>


                                <Form.Control
                                    readOnly
                                    type="text"
                                    value={company_zip_code}
                                    disabled
                                />

                            </Form.Group>

                     


                            {this.renderPhoneNumber()}


                        </Form>



                    </div>



                    <BottomHeader
                        history={this.state.history}
                        params={this.state.params}
                        location={this.state.location}
                    />

                    {this.changeEmailModal()}




                </div>

            )
        }



    }

    render() {


        return (

            <Fragment>

                {this.renderBody()}

            </Fragment>

        );

    }


}
const mapStateToProps = (state) => {

    const {
        initializing_user_page
    } = state.user_pages;


    const {
        logged_in,
        access_token,
        client,
        uid
    } = state.login;

    const  {
        initializing_page,
        full_name,
        email,
        phone_number_verified,
        phone_number,
        account_approved,
        company_address,
        company_city,
        company_name,
        company_state,
        company_zip_code,
        change_email_modal_visible,
        change_email_modal_success_message,
        change_email_modal_error_message,
        changing_email,
        new_email
    } = state.profile;


    return {
        initializing_user_page,
        logged_in,
        access_token,
        client,
        uid,
        initializing_page,
        full_name,
        email,
        phone_number_verified,
        phone_number,
        account_approved,
        company_address,
        company_city,
        company_name,
        company_state,
        company_zip_code,
        change_email_modal_visible,
        change_email_modal_success_message,
        change_email_modal_error_message,
        changing_email,
        new_email
    };


};

export default connect(mapStateToProps, {
    initializeProfilePage,
    clearProfilePageState,
    openCustomerChangeEmailModal,
    closeCustomerChangeEmailModal,
    changeCustomerEmail,
    newCustomerEmailChanged
})(Profile);
