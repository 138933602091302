import {
    REGISTER_CUSTOMER,
    REGISTER_CUSTOMER_COMPLETE,
    REGISTER_CUSTOMER_SUCCESS,
    REGISTER_CUSTOMER_FAILURE,
    REGISTER_CUSTOMER_FULL_NAME_CHANGED,
    REGISTER_CUSTOMER_EMAIL_CHANGED,
    REGISTER_CUSTOMER_PASSWORD_CHANGED,
    REGISTER_CUSTOMER_PASSWORD2_CHANGED,
    CLEAR_REGISTER_CUSTOMER_FORM,
    CLEAR_REGISTER_CUSTOMER_STATE,
    CLEAR_REGISTER_CUSTOMER_ERRORS,
    REGISTER_CUSTOMER_EMAIL_ERROR,
    REGISTER_CUSTOMER_PASSWORD_ERROR,
    REGISTER_CUSTOMER_PASSWORD2_ERROR,
    REGISTER_CUSTOMER_FULL_NAME_ERROR,
    HIDE_REGISTER_CUSTOMER_MODAL,
    REGISTER_CUSTOMER_COMPANY_ADDRESS_CHANGED,
    REGISTER_CUSTOMER_COMPANY_CITY_CHANGED,
    REGISTER_CUSTOMER_COMPANY_STATE_CHANGED,
    REGISTER_CUSTOMER_COMPANY_NAME_CHANGED,
    REGISTER_CUSTOMER_COMPANY_ZIP_CODE_CHANGED,
    REGISTER_CUSTOMER_COMPANY_ADDRESS_ERROR,
    REGISTER_CUSTOMER_COMPANY_CITY_ERROR,
    REGISTER_CUSTOMER_COMPANY_NAME_ERROR,
    REGISTER_CUSTOMER_COMPANY_STATE_ERROR,
    REGISTER_CUSTOMER_COMPANY_ZIP_CODE_ERROR,
    REGISTER_STATE_CODE_CHANGED,
    REGISTER_CUSTOMER_SELLER_PERMIT_CHANGED,
    REGISTER_CUSTOMER_SELLER_PERMIT_ERROR,
    REGISTER_CUSTOMER_EMAIL_CONSENT_GIVEN_CHANGED
} from "../actions/types";

const INITIAL_STATE = {
    email: '',
    password: '',
    password2: '',
    full_name: '',
    company_name: '',
    company_address: '',
    company_city: '',
    company_zip_code: '',
    company_state: '',
    state_code: '',
    error_messages: [],
    email_error: '',
    password_error: '',
    password2_error: '',
    full_name_error: '',
    company_name_error: '',
    company_address_error: '',
    company_city_error: '',
    company_zip_code_error: '',
    company_tax_exemption_id_error: '',
    company_state_error: '',
    registering_customer: false,
    success_message: '',
    register_customer_modal_visible: false,
    register_success: false,
    seller_permit: null,
    seller_permit_error: '',
    email_consent_given: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REGISTER_CUSTOMER_EMAIL_CONSENT_GIVEN_CHANGED:
            return{
                ...state,
                email_consent_given: action.payload
            };
        case REGISTER_CUSTOMER_SELLER_PERMIT_ERROR:
            return{
                ...state,
                seller_permit_error: action.payload
            };
        case REGISTER_CUSTOMER_SELLER_PERMIT_CHANGED:
            return{
                ...state,
                seller_permit: action.payload
            };
        case REGISTER_STATE_CODE_CHANGED:
            return{
                ...state,
                state_code: action.payload
            };
        case REGISTER_CUSTOMER_COMPANY_ZIP_CODE_ERROR:
            return{
                ...state,
                company_zip_code_error: action.payload
            };
        case REGISTER_CUSTOMER_COMPANY_STATE_ERROR:
            return{
                ...state,
                company_state_error: action.payload
            };
        case REGISTER_CUSTOMER_COMPANY_CITY_ERROR:
            return{
                ...state,
                company_city_error: action.payload
            };
        case REGISTER_CUSTOMER_COMPANY_ADDRESS_ERROR:
            return{
                ...state,
                company_address_error: action.payload
            };
        case REGISTER_CUSTOMER_COMPANY_NAME_ERROR:
            return{
                ...state,
                company_name_error: action.payload
            };
        case REGISTER_CUSTOMER_COMPANY_ZIP_CODE_CHANGED:
            return{
                ...state,
                company_zip_code: action.payload
            };
        case REGISTER_CUSTOMER_COMPANY_NAME_CHANGED:
            return{
                ...state,
                company_name: action.payload
            };
        case REGISTER_CUSTOMER_COMPANY_STATE_CHANGED:
            return{
                ...state,
                company_state: action.payload
            };
        case REGISTER_CUSTOMER_COMPANY_CITY_CHANGED:
            return{
                ...state,
                company_city: action.payload
            };
        case REGISTER_CUSTOMER_COMPANY_ADDRESS_CHANGED:
            return{
                ...state,
                company_address: action.payload
            };
        case HIDE_REGISTER_CUSTOMER_MODAL:
            return{
                ...state,
                register_customer_modal_visible: false,
                error_messages: [],
                success_message: ''
            };
        case CLEAR_REGISTER_CUSTOMER_ERRORS:
            return{
                ...state,
                error_messages: [],
                email_error: '',
                password_error: '',
                password2_error: '',
                full_name_error: '',
                company_name_error: '',
                company_address_error: '',
                company_city_error: '',
                company_zip_code_error: '',
                company_tax_exemption_id_error: '',
                company_state_error: '',
                seller_permit_error: ''
            };
        case CLEAR_REGISTER_CUSTOMER_FORM:
            return{
                ...state,
                email: '',
                password: '',
                password2: '',
                full_name: '',
                company_name: '',
                company_address: '',
                company_city: '',
                company_zip_code: '',
                company_state: '',
                state_code: '',
                seller_permit: null,
                email_consent_given: false
            };
        case REGISTER_CUSTOMER_FAILURE:
            return{
                ...state,
                error_messages: action.payload,
                register_customer_modal_visible: true
            };
        case REGISTER_CUSTOMER_SUCCESS:
            return{
                ...state,
                success_message: action.payload,
                register_customer_modal_visible: true,
                register_success: true
            };
        case REGISTER_CUSTOMER_COMPLETE:
            return{
                ...state,
                registering_customer: false
            };
        case REGISTER_CUSTOMER:
            return{
                ...state,
                registering_customer: true
            };
        case REGISTER_CUSTOMER_PASSWORD2_ERROR:
            return{
                ...state,
                password2_error: action.payload
            };
        case REGISTER_CUSTOMER_PASSWORD_ERROR:
            return{
                ...state,
                password_error: action.payload
            };
        case REGISTER_CUSTOMER_EMAIL_ERROR:
            return{
                ...state,
                email_error: action.payload
            };
        case REGISTER_CUSTOMER_FULL_NAME_ERROR:
            return{
                ...state,
                full_name_error: action.payload
            };
        case REGISTER_CUSTOMER_PASSWORD2_CHANGED:
            return{
                ...state,
                password2: action.payload
            };
        case REGISTER_CUSTOMER_PASSWORD_CHANGED:
            return{
                ...state,
                password: action.payload
            };
        case REGISTER_CUSTOMER_EMAIL_CHANGED:
            return{
                ...state,
                email: action.payload
            };
        case REGISTER_CUSTOMER_FULL_NAME_CHANGED:
            return{
                ...state,
                full_name: action.payload
            };
        case CLEAR_REGISTER_CUSTOMER_STATE:
            return{
                ...state,
                ...INITIAL_STATE
            };
        default:
            return state;
    }
};