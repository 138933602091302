import {
    LOGIN_ROUTE,
    LOGIN_CUSTOMER,
    LOGIN_CUSTOMER_SUCCESS,
    LOGIN_CUSTOMER_FAILURE,
    LOGOUT_SUCCESS,
    LOGOUT_ROUTE,
    CLEAR_LOGIN_FORM
} from "./types";

import axios from "axios";
import { getFormData } from "../helpers";

export const clearLoginForm = () => {

    return{
      type: CLEAR_LOGIN_FORM
    };

};

export const logoutCustomer = (access_token, client, uid, history) => {

    return(dispatch) => {

        const config = {
            headers: {
                "access-token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        axios.delete(LOGOUT_ROUTE, config)
            .then(() => {

                dispatch({type: LOGOUT_SUCCESS});

                history.push("/");

            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });

    };

};

export const loginCustomer = (email, password, history) => {

    return(dispatch) => {

        dispatch({type: LOGIN_CUSTOMER});

        const config = {
            headers: {
                "Accept": "application/json"
            }
        };

        let bodyFormData = getFormData({
            email: email,
            password: password
        });


        axios.post(LOGIN_ROUTE, bodyFormData, config)
            .then(response => {

                const data = response.data.data;

                console.log(data);

                const headers = response.headers;

                const access_token = headers["access-token"];

                const client = headers["client"];

                const uid = headers["uid"];

                console.log(headers);

                dispatch({type: LOGIN_CUSTOMER_SUCCESS, payload: {
                    access_token: access_token,
                    client: client,
                    uid: uid
                }});

                history.push("/");


            }).catch(error => {

            const errors = error.response.data.errors;

            console.log(errors);

            dispatch({ type: LOGIN_CUSTOMER_FAILURE, payload: errors });

        });

    };

};