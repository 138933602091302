import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {
    loginCustomer,
    clearLoginForm
} from "../actions";
import { Card, Spinner, Image, Form, Button} from "react-bootstrap";
import _ from "lodash";
import {isMobile} from 'react-device-detect';
import BottomHeader from "./BottomHeader";
import {Helmet} from "react-helmet";
import TopHeader from "./TopHeader";
import Recaptcha from 'react-google-invisible-recaptcha';
import {VERIFY_RECAPTCHA_TOKEN_ROUTE } from "../actions/types";
import axios from "axios";
import { getRecaptchaSiteKey } from "../helpers";

class Login extends Component{

    constructor(props) {

        super(props);

        const history = props.history;

        const params = props.match.params;

        const location = props.location;

        const width = window.innerWidth;

        const height = window.innerHeight;

        const email = "";

        const password = "";

        this.refRecaptcha = React.createRef();

        const verifying_recaptcha = false;

        const recaptcha_error = "";

        this.state = {
            history,
            params,
            location,
            width,
            height,
            email,
            password,
            verifying_recaptcha,
            recaptcha_error
        };

    }

    componentWillUnmount(){
        this.props.clearLoginForm();
    }

    loginErrors(){

        const { errors } = this.props;

        return _.map(errors, (error, index) => {

            return(

                <span
                    style={{
                        color: '#ff0000',
                        marginBottom: '30px',
                        textAlign: 'center'
                    }}
                    key={index}
                >
                    {error}
                </span>

            );

        });

    }

    authenticateCustomer(){

        const { loginCustomer } = this.props;

        const {email, password, history } = this.state;

        loginCustomer(email, password, history);


    }


    recaptchaError(){

        const { recaptcha_error } = this.state;

        if(recaptcha_error.length > 0){

            return(

                <span
                    style={{
                        color: '#ff0000',
                        marginBottom: '30px',
                        textAlign: 'center'
                    }}
                >
                    {recaptcha_error}
                </span>

            )

        }

    }

    renderLoginButton(){

        const { loading } = this.props;

        const { verifying_recaptcha } = this.state;

        if(loading || verifying_recaptcha){

            return(


                <div style={{
                    marginBottom: '10px'
                }}>

                    <Spinner animation="border" variant="primary" />

                </div>



            );

        }else{

            return(

                <Fragment>

                    {this.recaptchaError()}

                    <Button
                        variant="outline-success"
                        style={{
                            width: isMobile ? this.state.width - 100 : '30%',
                            borderRadius: '10px',
                            marginBottom:   '30px'
                        }}
                        onClick={() => {
                            this.refRecaptcha.current.execute();
                        }}
                    >
                        Login
                    </Button>



                </Fragment>

            );

        }

    }


    renderBody(){

        const {
            initializing_user_page
        } = this.props;

        const { history } = this.state;


        if(initializing_user_page){

            return(

                <div
                    style={{
                        backgroundColor: '#f7f1eb'
                    }}
                >

                    <Spinner animation="border" variant="primary" className="spinner" />

                </div>

            );

        }else{

            return(

                <div
                    style={{
                        backgroundColor: '#f7f1eb'
                    }}
                >

                    <Helmet>

                        <title>Login</title>

                    </Helmet>

                    <TopHeader
                        history={this.state.history}
                        params={this.state.params}
                        location={this.state.location}
                    />



                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: '4rem'
                        }}
                    >

                        <Card style={{
                            width: isMobile ?  this.state.width - 50 :  this.state.width / 2,
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}>




                            <Card.Body
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    marginBottom: '2rem',
                                    marginTop: '2rem'
                                }}
                            >

                                <p style={{
                                    fontSize: isMobile ? '35px' : '40px',
                                    marginBottom: isMobile ? '2rem' : '3rem',
                                    textAlign: 'center'
                                }}>
                                    Login
                                </p>

                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                    }}
                                    style={{
                                        marginBottom: '1rem'
                                    }}
                                >

                                    <Form.Group
                                        className="login-input"
                                        style={{
                                            width: isMobile ? this.state.width - 100 : this.state.width / 2.5
                                        }}
                                    >

                                        <Form.Control
                                            type="email"
                                            placeholder="Email"
                                            value={this.state.email}
                                            onChange={(e) => {
                                                this.setState({email: e.target.value});
                                            }}
                                        />

                                    </Form.Group>

                                    <Form.Group
                                        className="login-input"
                                        style={{
                                            width: isMobile ? this.state.width - 100 : this.state.width / 2.5
                                        }}
                                    >

                                        <Form.Control
                                            type="password"
                                            placeholder="Password"
                                            value={this.state.password}
                                            onChange={(e) => {
                                                this.setState({password: e.target.value});
                                            }}
                                            onKeyPress={(e) => {
                                                if(e.charCode === 13){

                                                    e.preventDefault();

                                                    this.refRecaptcha.current.execute();

                                                }
                                            }}
                                        />

                                    </Form.Group>





                                </Form>

                                {this.loginErrors()}

                                {this.renderLoginButton()}

                                <span
                                    className="login-form-nav-link"
                                    onClick={() => {
                                        history.push("/resend-unlock");
                                    }}
                                >
                                   Resend Unlock Link
                                </span>

                                <span
                                    className="login-form-nav-link"
                                    onClick={() => {
                                        history.push("/send-reset-link");
                                    }}
                                >
                                    Forgot your password?
                                </span>


                                <span
                                    className="login-form-nav-link"
                                    onClick={() => {
                                        history.push("/resend-confirmation");
                                    }}
                                >
                                   Resend Confirmation Instructions
                                </span>





                                <Recaptcha
                                    ref={this.refRecaptcha}
                                    sitekey={getRecaptchaSiteKey()}
                                    onResolved={() => {

                                        this.setState({verifying_recaptcha: true, recaptcha_error: ''});

                                        const token =  this.refRecaptcha.current.getResponse();

                                        console.log(token);

                                        const config = {
                                            headers: {
                                                "Accept": "application/json"
                                            }
                                        };

                                        let bodyFormData = new FormData();

                                        bodyFormData.append('token', token);


                                        axios.post(VERIFY_RECAPTCHA_TOKEN_ROUTE, bodyFormData, config)
                                            .then(response => {

                                                const data = response.data;

                                                const success = data.success;

                                                this.setState({verifying_recaptcha: false});

                                                if(success){

                                                    this.authenticateCustomer();

                                                }else{

                                                    this.setState({recaptcha_error: 'An error occurred. Please try again.'});

                                                }



                                            }).catch(error => {

                                            console.log(error);

                                            this.setState({recaptcha_error: 'An error occurred. Please try again.', verifying_recaptcha: false});

                                        });


                                    }}
                                />


                            </Card.Body>




                        </Card>


                    </div>


                    <BottomHeader
                        history={this.state.history}
                        params={this.state.params}
                        location={this.state.location}
                    />




                </div>

            )
        }



    }


    render() {


        return (

            <Fragment>

                {this.renderBody()}

            </Fragment>

        );

    }

}

const mapStateToProps = (state) => {

    const {
        initializing_user_page
    } = state.user_pages;

    const {
        logged_in,
        loading,
        errors
    } = state.login;

    return {
        initializing_user_page,
        logged_in,
        loading,
        errors
    };

};

export default connect(mapStateToProps, {
    loginCustomer,
    clearLoginForm
})(Login);


