import {
    LOGOUT_SUCCESS,
    SEND_SMS_VERIFICATION,
    SEND_SMS_VERIFICATION_COMPLETE,
    SEND_SMS_VERIFICATION_SUCCESS,
    SEND_SMS_VERIFICATION_FAILURE,
    EXIT_SMS_VERIFICATION_MODAL,
    OPEN_SMS_VERIFICATION_MODAL,
    CLEAR_PHONE_NUMBER_STATE,
    CHECK_SMS_VERIFICATION_CODE,
    CHECK_SMS_VERIFICATION_CODE_COMPLETE,
    CHECK_SMS_VERIFICATION_CODE_FAILURE,
    RESEND_SMS_VERIFICATION_CODE,
    RESEND_SMS_VERIFICATION_CODE_COMPLETE,
    RESEND_SMS_VERIFICATION_CODE_FAILURE
} from "../actions/types";

const INITIAL_STATE = {
    sending_sms: false,
    sms_verification_modal_visible: false,
    send_sms_verification_error: '',
    resending_sms: false,
    sms_verification_modal_error: '',
    verifying_code: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RESEND_SMS_VERIFICATION_CODE_FAILURE:
            return{
                ...state,
                sms_verification_modal_error: action.payload
            };
        case RESEND_SMS_VERIFICATION_CODE_COMPLETE:
            return{
                ...state,
                resending_sms: false
            };
        case RESEND_SMS_VERIFICATION_CODE:
            return{
                ...state,
                resending_sms: true,
                sms_verification_modal_error: ''
            };
        case CHECK_SMS_VERIFICATION_CODE_FAILURE:
            return{
                ...state,
                sms_verification_modal_error: action.payload
            };
        case CHECK_SMS_VERIFICATION_CODE_COMPLETE:
            return{
                ...state,
                verifying_code: false
            };
        case CHECK_SMS_VERIFICATION_CODE:
            return{
                ...state,
                verifying_code: true,
                sms_verification_modal_error: ''
            };
        case EXIT_SMS_VERIFICATION_MODAL:
            return{
                ...state,
                sms_verification_modal_visible: false,
                resending_sms: false,
                sms_verification_modal_error: '',
                verifying_code: false
            };
        case OPEN_SMS_VERIFICATION_MODAL:
            return{
                ...state,
                sms_verification_modal_visible: true
            };
        case SEND_SMS_VERIFICATION_FAILURE:
            return{
                ...state,
                send_sms_verification_error: action.payload
            };
        case SEND_SMS_VERIFICATION_SUCCESS:
            return{
                ...state,
                sms_verification_modal_visible: true
            };
        case SEND_SMS_VERIFICATION_COMPLETE:
            return{
                ...state,
                sending_sms: false
            };
        case SEND_SMS_VERIFICATION:
            return{
                ...state,
                sending_sms: true,
                send_sms_verification_error: ''
            };
        case CLEAR_PHONE_NUMBER_STATE:
            return{
                ...state,
                ...INITIAL_STATE
            };
        case LOGOUT_SUCCESS:
            return{
                ...INITIAL_STATE
            };
        default:
            return state;
    }
};