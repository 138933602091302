import {
    SEND_RESET_LINK,
    SEND_RESET_LINK_SUCCESS,
    SEND_RESET_LINK_FAILURE,
    SEND_RESET_LINK_COMPLETE,
    CLEAR_SEND_RESET_LINK_STATE
} from "../actions/types";

const INITIAL_STATE = {
    loading: false,
    message: "",
    errors: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLEAR_SEND_RESET_LINK_STATE:
            return{
                ...state,
                ...INITIAL_STATE
            };
        case SEND_RESET_LINK_FAILURE:
            return{
                ...state,
                errors: action.payload
            };
        case SEND_RESET_LINK_SUCCESS:
            return{
                ...state,
                message: action.payload
            };
        case SEND_RESET_LINK_COMPLETE:
            return{
                ...state,
                loading: false
            };
        case SEND_RESET_LINK:
            return{
                ...state,
                loading: true,
                message: '',
                errors: []
            };
        default:
            return state;
    }
};