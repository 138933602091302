import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import _ from "lodash";
import {isMobile, isDesktop, isTablet} from 'react-device-detect'
import TopHeader from "./TopHeader";
import {Spinner, Card, Image, Button, Form, Modal} from "react-bootstrap";
import {
    registerCustomer,
    registerCustomerFullNameChanged,
    registerCustomerEmailChanged,
    registerCustomerPasswordChanged,
    registerCustomerPassword2Changed,
    hideRegisterCustomerModal,
    clearRegisterCustomerState,
    registerCustomerCompanyAddressChanged,
    registerCustomerCompanyCityChanged,
    registerCustomerCompanyStateChanged,
    registerCustomerCompanyNameChanged,
    registerCustomerCompanyZipCodeChanged,
    registerStateCodeChanged,
    registerCustomerSellerPermitChanged,
    registerCustomerEmailConsentGivenChanged
} from "../actions";
import {Helmet} from "react-helmet";
import Recaptcha from 'react-google-invisible-recaptcha';
import {VERIFY_RECAPTCHA_TOKEN_ROUTE } from "../actions/types";
import axios from "axios";
import { getRecaptchaSiteKey } from "../helpers";
import BottomHeader from "./BottomHeader";
import {State, City} from 'country-state-city';
import { Typeahead } from 'react-bootstrap-typeahead';


class Register extends Component{

    constructor(props){

        super(props);

        const history = props.history;

        const params = props.match.params;

        const location = props.location;

        const width = window.innerWidth;

        const height = window.innerHeight;

        this.refRecaptcha = React.createRef();

        const verifying_recaptcha = false;




        this.state = {
            history,
            params,
            location,
            width,
            height,
            verifying_recaptcha
        };

    }

    componentDidMount(){

        const { logged_in } = this.props;

        const { history } = this.state;

        if(logged_in){

            history.push("/");

        }


    }

    componentWillUnmount(){

        this.props.clearRegisterCustomerState();

    }


    registerModalBody(){

        const {  error_messages, success_message } = this.props;

        if(success_message.length > 0){

            return(

                <p style={{
                    fontSize: '18px',
                    color: '#008000'
                }}>
                    {success_message}
                </p>

            );

        }else{



            return _.map(error_messages, (error_message, index) => {

                return(
                    <p
                        style={{
                            fontSize: '18px',
                            color: '#FF0000'
                        }}
                        key={index}
                    >
                        {error_message}
                    </p>
                )

            });


        }


    }

    exitRegisterModal(){

        const {
            hideRegisterCustomerModal,
            register_success
        } = this.props;

        const {
            history
        } = this.state;

        hideRegisterCustomerModal();

        if(register_success){

            history.push("/login");

        }


    }

    registerModal(){

        const {
            register_customer_modal_visible,
            hideRegisterCustomerModal,
            register_success
        } = this.props;

        const {
            history
        } = this.state;

        if(register_customer_modal_visible){

            return(


                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={register_customer_modal_visible}
                    onHide={() => {

                        this.exitRegisterModal();

                    }}
                >
                    <Modal.Header closeButton>

                        <Modal.Title id="contained-modal-title-vcenter">
                            Result
                        </Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        {this.registerModalBody()}

                    </Modal.Body>



                    <Modal.Footer>

                        <Button
                            variant="secondary"
                            onClick={() => {

                                this.exitRegisterModal();

                            }}>
                            Close
                        </Button>

                    </Modal.Footer>

                </Modal>

            );

        }


    }




    renderSubmitButton(){

        const { registering_customer } = this.props;

        const { verifying_recaptcha } = this.state;


        if(verifying_recaptcha || registering_customer){

            return(

                <div style={{
                    marginBottom: '10px'
                }}>

                    <Spinner animation="border" variant="primary" />

                </div>



            );


        }else{

            return(

                <Fragment>



                    <Button
                        variant="outline-success"
                        style={{
                            width: isMobile ? this.state.width - 100 : '30%',
                            borderRadius: '10px',
                            fontSize: '18px',
                            marginTop: isMobile ? 0 : '20px',
                            marginBottom: '20px'
                        }}
                        onClick={() => {
                            this.refRecaptcha.current.execute();
                        }}
                    >
                        Create Account
                    </Button>




                </Fragment>



            );


        }


    }

    companyCityError(){

        const { company_city_error } = this.props;

        if(!_.isEmpty(company_city_error)){

            return(

                <span style={{
                    fontSize: '14px',
                    color: '#dc3545',
                    marginTop: '5px'
                }}>
                    {company_city_error}
                </span>

            );

        }


    }


    renderCitySelector(){

        const { state_code, company_city_error, registerCustomerCompanyCityChanged } = this.props;

        if(!_.isEmpty(state_code)){

            return(

                <Form.Group
                    style={{
                        width: isMobile ? this.state.width - 100 : this.state.width / 2,
                        marginBottom: '30px'
                    }}
                >


                    <Typeahead
                        id="city-selector"
                        labelKey={city => `${city.name}`}
                        onChange={(selected_cities) => {

                            // console.log(selected_states);


                            if(!_.isEmpty(selected_cities)){

                                const new_selected_city = selected_cities[0];


                                const new_selected_city_name = new_selected_city.name;



                                registerCustomerCompanyCityChanged(new_selected_city_name);


                            }else{


                                registerCustomerCompanyCityChanged('');


                            }


                        }}
                        options={City.getCitiesOfState('US', state_code)}
                        placeholder="Select City"
                    />

                    {this.companyCityError()}

                </Form.Group>


            );

        }

    }


    submitForm(token){

        const {
            full_name,
            email,
            password,
            password2,
            registerCustomer,
            company_name,
            company_address,
            company_city,
            company_state,
            company_zip_code,
            seller_permit,
            email_consent_given
        } = this.props;

        const { history } = this.state;

        registerCustomer(
            history,
            email,
            password,
            password2,
            full_name,
            company_name,
            company_address,
            company_city,
            company_state,
            company_zip_code,
            seller_permit,
            email_consent_given,
            token
        );

    }

    companyStateError(){

        const { company_state_error } = this.props;

        if(!_.isEmpty(company_state_error)){

            return(

                <span style={{
                    fontSize: '14px',
                    color: '#dc3545',
                    marginTop: '5px'
                }}>
                    {company_state_error}
                </span>

            );

        }

    }


    getCountryStates(){

        let states = [];

        _.map(State.getStatesOfCountry('US'), (state_item, index) => {

            const cities = City.getCitiesOfState('US', state_item.isoCode);

            if(!_.isEmpty(cities)){

                states.push(state_item);

            }

        });



        return states;

    }


    sellerPermitError(){

        const { seller_permit_error } = this.props;

        if(seller_permit_error.length > 0){

            return(

                <p style={{
                    color: 'red',
                    fontSize: 14
                }}>
                    {seller_permit_error}
                </p>

            );

        }


    }

    renderBody(){

        const {
            initializing_user_page,
            full_name,
            registerCustomerFullNameChanged,
            full_name_error,
            email,
            email_error,
            registerCustomerEmailChanged,
            password,
            password_error,
            registerCustomerPasswordChanged,
            password2,
            registerCustomerPassword2Changed,
            password2_error,
            company_name,
            company_address,
            company_city,
            company_zip_code,
            company_state,
            company_name_error,
            company_address_error,
            company_city_error,
            company_zip_code_error,
            company_state_error,
            registerCustomerCompanyAddressChanged,
            registerCustomerCompanyCityChanged,
            registerCustomerCompanyStateChanged,
            registerCustomerCompanyNameChanged,
            registerCustomerCompanyZipCodeChanged,
            registerStateCodeChanged,
            state_code,
            registerCustomerSellerPermitChanged,
            email_consent_given,
            registerCustomerEmailConsentGivenChanged
        } = this.props;



        if(initializing_user_page){

            return(

                <div
                    style={{
                        backgroundColor: '#f7f1eb'
                    }}
                >

                    <Spinner animation="border" variant="primary" className="spinner" />

                </div>

            );

        }else{

            return(

                <div
                    style={{
                        backgroundColor: '#f7f1eb'
                    }}
                >


                    <Helmet>

                        <title>Join Bodega Liquidation</title>

                        <meta
                            name="description"
                            content="Please fill in this form to create an account and join Bodega Liquidation."
                        />

                    </Helmet>

                    <TopHeader
                        history={this.state.history}
                        params={this.state.params}
                        location={this.state.location}
                    />

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: '4rem'
                        }}
                    >

                        <Card style={{
                            width: isMobile ? this.state.width - 50 :  this.state.width / 1.5,
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}>


                            <Card.Body
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    marginBottom: '1rem',
                                    marginTop: '1rem'
                                }}
                            >


                                <Form
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                    }}
                                    onSubmit={(e) => e.preventDefault()}
                                >


                                    <p style={{
                                        fontSize: isMobile ? '22px' : '35px',
                                        marginBottom: isMobile ? '2rem' : '3rem',
                                        textAlign: 'center'
                                    }}>
                                        Personal Information
                                    </p>



                                    <Form.Group
                                        style={{
                                            width: isMobile ? this.state.width - 100 : this.state.width / 2,
                                            marginBottom: '30px'
                                        }}
                                    >

                                        <Form.Control
                                            type="email"
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => {
                                                registerCustomerEmailChanged(e.target.value);
                                            }}
                                            isInvalid={!_.isEmpty(email_error)}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {email_error}
                                        </Form.Control.Feedback>

                                    </Form.Group>

                                    <Form.Group
                                        style={{
                                            width: isMobile ? this.state.width - 100 : this.state.width / 2,
                                            marginBottom: '30px'
                                        }}
                                    >

                                        <Form.Control
                                            type="password"
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => {
                                                registerCustomerPasswordChanged(e.target.value);
                                            }}
                                            isInvalid={!_.isEmpty(password_error)}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {password_error}
                                        </Form.Control.Feedback>

                                    </Form.Group>

                                    <Form.Group
                                        style={{
                                            width: isMobile ? this.state.width - 100 : this.state.width / 2,
                                            marginBottom: '30px'
                                        }}
                                    >

                                        <Form.Control
                                            type="password"
                                            placeholder="Re-type Password"
                                            value={password2}
                                            onChange={(e) => {
                                                registerCustomerPassword2Changed(e.target.value);
                                            }}
                                            isInvalid={!_.isEmpty(password2_error)}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {password2_error}
                                        </Form.Control.Feedback>

                                    </Form.Group>

                                    <Form.Group
                                        style={{
                                            width: isMobile ? this.state.width - 100 : this.state.width / 2,
                                            marginBottom: '30px'
                                        }}
                                    >

                                        <Form.Control
                                            placeholder="Full Name"
                                            value={full_name}
                                            onChange={(e) => {
                                                registerCustomerFullNameChanged(e.target.value);
                                            }}
                                            isInvalid={!_.isEmpty(full_name_error)}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {full_name_error}
                                        </Form.Control.Feedback>

                                    </Form.Group>


                                    <p style={{
                                        fontSize: isMobile ? '22px' : '35px',
                                        marginBottom: isMobile ? '2rem' : '3rem',
                                        textAlign: 'center'
                                    }}>
                                        Company Information
                                    </p>



                                    <Form.Group
                                        style={{
                                            width: isMobile ? this.state.width - 100 : this.state.width / 2,
                                            marginBottom: '30px'
                                        }}
                                    >

                                        <Form.Control
                                            placeholder="Name"
                                            value={company_name}
                                            onChange={(e) => {
                                                registerCustomerCompanyNameChanged(e.target.value);
                                            }}
                                            isInvalid={!_.isEmpty(company_name_error)}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {company_name_error}
                                        </Form.Control.Feedback>

                                    </Form.Group>

                                    <Form.Group
                                        style={{
                                            width: isMobile ? this.state.width - 100 : this.state.width / 2,
                                            marginBottom: '30px'
                                        }}
                                    >

                                        <Form.Control
                                            placeholder="Address"
                                            value={company_address}
                                            onChange={(e) => {
                                                registerCustomerCompanyAddressChanged(e.target.value);
                                            }}
                                            isInvalid={!_.isEmpty(company_address_error)}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {company_address_error}
                                        </Form.Control.Feedback>

                                    </Form.Group>


                                    <Form.Group
                                        style={{
                                            width: isMobile ? this.state.width - 100 : this.state.width / 2,
                                            marginBottom: '30px'
                                        }}
                                    >





                                        <Typeahead
                                            id="state-selector"
                                            labelKey={state => `${state.name}`}
                                            onChange={(selected_states) => {

                                                // console.log(selected_states);


                                                if(!_.isEmpty(selected_states)){

                                                    const new_selected_state = selected_states[0];

                                                    registerStateCodeChanged(new_selected_state.isoCode);

                                                    const new_selected_state_name = new_selected_state.name;

                                                    registerCustomerCompanyStateChanged(new_selected_state_name);



                                                }else{




                                                    registerStateCodeChanged('');

                                                    registerCustomerCompanyStateChanged('');


                                                    registerCustomerCompanyCityChanged('');




                                                }


                                            }}
                                            options={this.getCountryStates()}
                                            placeholder="Select State"
                                        />

                                        {this.companyStateError()}


                                    </Form.Group>


                                    {this.renderCitySelector()}



                                    <Form.Group
                                        style={{
                                            width: isMobile ? this.state.width - 100 : this.state.width / 2,
                                            marginBottom: '30px'
                                        }}
                                    >

                                        <Form.Control
                                            placeholder="Zip Code"
                                            value={company_zip_code}
                                            onChange={(e) => {
                                                registerCustomerCompanyZipCodeChanged(e.target.value);
                                            }}
                                            isInvalid={!_.isEmpty(company_zip_code_error)}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {company_zip_code_error}
                                        </Form.Control.Feedback>

                                    </Form.Group>

                                    <Form.Group
                                        style={{
                                            width: isMobile ? this.state.width - 100 : this.state.width / 2,
                                            marginBottom: '30px'
                                        }}
                                    >

                                        <p>Seller Permit (*.pdf, *.doc, *.docx)</p>

                                        {this.sellerPermitError()}

                                        <input
                                            type="file"
                                            accept=".pdf,.doc,.docx,.odt"
                                            onChange={e => {
                                                registerCustomerSellerPermitChanged(e.target.files[0]);
                                            }}
                                        />



                                    </Form.Group>



                                    <Form.Check
                                        type="checkbox"
                                        checked={email_consent_given}
                                        label="I would like to receive marketing emails and promotions"
                                        onChange={(e) => {
                                            registerCustomerEmailConsentGivenChanged(!email_consent_given);
                                        }}
                                        style={{
                                            marginBottom: isMobile ? '2rem' : '20px'
                                        }}
                                    />


                                    {this.renderSubmitButton()}


                                    <Recaptcha
                                        ref={this.refRecaptcha}
                                        sitekey={getRecaptchaSiteKey()}
                                        onResolved={() => {

                                            this.setState({verifying_recaptcha: true});

                                            const token =  this.refRecaptcha.current.getResponse();

                                            this.setState({verifying_recaptcha: false});

                                            // console.log(token);

                                            this.submitForm(token);



                                        }}
                                    />


                                </Form>

                            </Card.Body>


                        </Card>



                    </div>




                    <BottomHeader
                        history={this.state.history}
                        params={this.state.params}
                        location={this.state.location}
                    />

                    {this.registerModal()}



                </div>

            )
        }

    }


    render() {


        return (

            <Fragment>

                {this.renderBody()}

            </Fragment>

        );

    }



}

const mapStateToProps = (state) => {

    const {
        initializing_user_page
    } = state.user_pages;

    const {
        full_name,
        email,
        password,
        password2,
        error_messages,
        email_error,
        password_error,
        password2_error,
        full_name_error,
        registering_customer,
        success_message,
        register_customer_modal_visible,
        register_success,
        company_name,
        company_address,
        company_city,
        company_zip_code,
        company_state,
        company_name_error,
        company_address_error,
        company_city_error,
        company_zip_code_error,
        company_state_error,
        state_code,
        seller_permit,
        seller_permit_error,
        email_consent_given
    } = state.register_customer;

    const {
        logged_in
    } = state.login;

    return {
        initializing_user_page,
        full_name,
        email,
        password,
        password2,
        error_messages,
        email_error,
        password_error,
        password2_error,
        full_name_error,
        registering_customer,
        success_message,
        register_customer_modal_visible,
        logged_in,
        register_success,
        company_name,
        company_address,
        company_city,
        company_zip_code,
        company_state,
        company_name_error,
        company_address_error,
        company_city_error,
        company_zip_code_error,
        company_state_error,
        state_code,
        seller_permit,
        seller_permit_error,
        email_consent_given
    };


};

export default connect(mapStateToProps, {
    registerCustomer,
    registerCustomerFullNameChanged,
    registerCustomerEmailChanged,
    registerCustomerPasswordChanged,
    registerCustomerPassword2Changed,
    hideRegisterCustomerModal,
    clearRegisterCustomerState,
    registerCustomerCompanyAddressChanged,
    registerCustomerCompanyCityChanged,
    registerCustomerCompanyStateChanged,
    registerCustomerCompanyNameChanged,
    registerCustomerCompanyZipCodeChanged,
    registerStateCodeChanged,
    registerCustomerSellerPermitChanged,
    registerCustomerEmailConsentGivenChanged
})(Register);