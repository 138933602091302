import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import _ from "lodash";
import {isMobile, isDesktop, isTablet} from 'react-device-detect'
import TopHeader from "./TopHeader";
import {Spinner, Card, Image, Button, OverlayTrigger, Popover, Breadcrumb, ButtonGroup, Form, Modal} from "react-bootstrap";
import {
    sendSmsVerificationCode,
    exitSmsVerificationModal,
    clearPhoneNumberState,
    checkSmSVerificationCode,
    resendSmSVerificationCode
} from "../actions";
import BottomHeader from "./BottomHeader";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'


class PhoneNumber extends Component{

    constructor(props){

        super(props);

        const history = props.history;

        const params = props.match.params;

        const location = props.location;

        const width = window.innerWidth;

        const height = window.innerHeight;

        const phone_number = "";

        const verification_code = "";

        this.state = {
            history,
            params,
            location,
            width,
            height,
            phone_number,
            verification_code
        };

    }

    componentWillUnmount(){
        this.props.clearPhoneNumberState();
    }

    componentDidMount(){

        const { history } = this.state;

        const { logged_in } = this.props;

        if(!logged_in){

            history.push("/");

        }

    }


    renderContinueButton(){

        const { phone_number, history } = this.state;

        const {
            sending_sms,
            sendSmsVerificationCode,
            access_token,
            client,
            uid
        } = this.props;

        console.log(phone_number);

        if(sending_sms){

            return(

                <div style={{
                    marginBottom: '10px'
                }}>

                    <Spinner animation="border" variant="primary" />

                </div>

            );

        }else{

            if(_.isEmpty(phone_number)){


                return(

                    <Button
                        variant="success"
                        style={{
                            width: isMobile ? this.state.width - 100 :'15%',
                            borderRadius: isMobile ? '20px' : '10px',
                            fontSize: '18px',
                            marginBottom: '30px',
                        }}
                        disabled
                    >
                        Continue
                    </Button>

                );


            }else{

                return(

                    <Button
                        variant="success"
                        style={{
                            width: isMobile ? this.state.width - 100 :'15%',
                            borderRadius: isMobile ? '20px' : '10px',
                            fontSize: '18px',
                            marginBottom: '30px',
                        }}
                        onClick={() => {
                            sendSmsVerificationCode(history, access_token, client, uid, phone_number);
                        }}
                    >
                        Continue
                    </Button>

                );


            }

        }


    }

    sendSmSVerificationError(){

        const { send_sms_verification_error } = this.props;

        if(send_sms_verification_error.length > 0){

            return(

                <p
                    style={{
                        fontSize: '18px',
                        color: '#FF0000',
                        textAlign: 'center',
                        marginBottom: '30px'
                    }}
                >
                    {send_sms_verification_error}
                </p>

            );

        }


    }

    closeSmsVerificationModal(){

        this.setState({verification_code: ''});

        this.props.exitSmsVerificationModal();


    }


    smsVerificationModalBody(){

        const {
            sms_verification_modal_error,
            access_token,
            client,
            uid,
            resendSmSVerificationCode,
            resending_sms,
            verifying_code
        } = this.props;

        const {  phone_number, verification_code, history } = this.state;

        if(resending_sms || verifying_code){

            return(

                <div style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>

                    <Spinner animation="border" variant="primary"  />

                </div>

            )

        }else{

            return(

                <Form.Group
                    style={{
                        marginBottom: '20px'
                    }}
                >

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'row'
                        }}
                    >
                        <Form.Label
                        >
                            Verification Code
                        </Form.Label>

                        <Button
                            id="resend-code-button"
                            variant="link"
                            onClick={() => {
                                resendSmSVerificationCode(history, access_token, client, uid, phone_number);
                            }}
                        >
                            Resend Code
                        </Button>

                    </div>

                    <Form.Control
                        placeholder="code"
                        value={verification_code}
                        onChange={(e) => {
                            this.setState({verification_code: e.target.value});
                        }}
                        isInvalid={!_.isEmpty(sms_verification_modal_error)}
                    />

                    <Form.Control.Feedback type="invalid">
                        {sms_verification_modal_error}
                    </Form.Control.Feedback>

                </Form.Group>
            );


        }


    }


    verificationModalFooter(){

        const {
            checkSmSVerificationCode,
            access_token,
            client,
            uid,
            verifying_code
        } = this.props;

        const {  phone_number, verification_code, history } = this.state;

        if(!verifying_code){

            return(

                <Modal.Footer>

                    <Button
                        variant="success"
                        onClick={() => {
                            checkSmSVerificationCode(history, access_token, client, uid, phone_number, verification_code);
                        }}>
                        Submit
                    </Button>

                    <Button
                        variant="secondary"
                        onClick={() => {
                            this.closeSmsVerificationModal();
                        }}>
                        Close
                    </Button>

                </Modal.Footer>

            );

        }


    }

    renderSmsVerificationModal(){

        const {
            sms_verification_modal_visible
        } = this.props;


        if(sms_verification_modal_visible){

            return(

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={sms_verification_modal_visible}
                    onHide={() => {
                        this.closeSmsVerificationModal();
                    }}
                >
                    <Modal.Header closeButton>

                        <Modal.Title id="contained-modal-title-vcenter">
                            Enter SMS Verification Code
                        </Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        {this.smsVerificationModalBody()}


                    </Modal.Body>


                    {this.verificationModalFooter()}


                </Modal>


            );

        }

    }

    renderBody(){

        const {
            initializing_user_page,
        } = this.props;

        const {
            phone_number
        } = this.state;

        if(initializing_user_page){

            return(

                <div
                    style={{
                        backgroundColor: '#f7f1eb'
                    }}
                >

                    <Spinner animation="border" variant="primary" className="spinner" />

                </div>

            );

        }else{

            return(

                <div style={{
                    backgroundColor: '#f7f1eb'
                }}>


                    <TopHeader
                        history={this.state.history}
                        params={this.state.params}
                        location={this.state.location}
                    />


                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: '4rem',
                            flexDirection: 'column',
                            alignItems: 'center',
                            flex: 1
                        }}
                    >

                        <p style={{
                            fontSize: '30px',
                            marginBottom: isMobile ? '2rem' : '3rem',
                            textAlign: 'center'
                        }}>
                            Phone Number
                        </p>

                        <PhoneInput
                            style={{
                                width: isMobile ? this.state.width - 50 :  this.state.width / 4,
                                marginBottom: '30px'
                            }}
                            placeholder="Enter phone number"
                            value={phone_number}
                            onChange={(number) => {

                                console.log(number);

                                if(number !== undefined && number !== null){

                                    this.setState({phone_number: number});

                                }else{

                                    this.setState({phone_number: ''});

                                }

                            }}
                        />

                        {this.sendSmSVerificationError()}


                        {this.renderContinueButton()}


                    </div>


                    {this.renderSmsVerificationModal()}

                    <BottomHeader
                        history={this.state.history}
                        params={this.state.params}
                        location={this.state.location}
                    />




                </div>

            )
        }



    }


    render() {


        return (

            <Fragment>

                {this.renderBody()}

            </Fragment>

        );

    }



}

const mapStateToProps = (state) => {

    const {
        initializing_user_page
    } = state.user_pages;


    const {
        logged_in,
        access_token,
        client,
        uid
    } = state.login;

    const {
        sending_sms,
        sms_verification_modal_visible,
        send_sms_verification_error,
        resending_sms,
        sms_verification_modal_error,
        verifying_code
    } = state.phone_number;

    return {
        initializing_user_page,
        logged_in,
        access_token,
        client,
        uid,
        sending_sms,
        sms_verification_modal_visible,
        send_sms_verification_error,
        resending_sms,
        sms_verification_modal_error,
        verifying_code
    };


};

export default connect(mapStateToProps, {
    sendSmsVerificationCode,
    exitSmsVerificationModal,
    clearPhoneNumberState,
    checkSmSVerificationCode,
    resendSmSVerificationCode
})(PhoneNumber);

