import {
    REGISTER_CUSTOMER_ROUTE,
    REGISTER_CUSTOMER,
    REGISTER_CUSTOMER_COMPLETE,
    REGISTER_CUSTOMER_SUCCESS,
    REGISTER_CUSTOMER_FAILURE,
    REGISTER_CUSTOMER_FULL_NAME_CHANGED,
    REGISTER_CUSTOMER_EMAIL_CHANGED,
    REGISTER_CUSTOMER_PASSWORD_CHANGED,
    REGISTER_CUSTOMER_PASSWORD2_CHANGED,
    REGISTER_CUSTOMER_COMPANY_ADDRESS_CHANGED,
    REGISTER_CUSTOMER_COMPANY_CITY_CHANGED,
    REGISTER_CUSTOMER_COMPANY_STATE_CHANGED,
    REGISTER_CUSTOMER_COMPANY_NAME_CHANGED,
    REGISTER_CUSTOMER_COMPANY_ZIP_CODE_CHANGED,
    REGISTER_CUSTOMER_COMPANY_ADDRESS_ERROR,
    REGISTER_CUSTOMER_COMPANY_CITY_ERROR,
    REGISTER_CUSTOMER_COMPANY_NAME_ERROR,
    REGISTER_CUSTOMER_COMPANY_STATE_ERROR,
    REGISTER_CUSTOMER_COMPANY_ZIP_CODE_ERROR,
    CLEAR_REGISTER_CUSTOMER_FORM,
    CLEAR_REGISTER_CUSTOMER_STATE,
    CLEAR_REGISTER_CUSTOMER_ERRORS,
    REGISTER_CUSTOMER_EMAIL_ERROR,
    REGISTER_CUSTOMER_PASSWORD_ERROR,
    REGISTER_CUSTOMER_PASSWORD2_ERROR,
    REGISTER_CUSTOMER_FULL_NAME_ERROR,
    HIDE_REGISTER_CUSTOMER_MODAL,
    REGISTER_STATE_CODE_CHANGED,
    REGISTER_CUSTOMER_SELLER_PERMIT_CHANGED,
    REGISTER_CUSTOMER_SELLER_PERMIT_ERROR,
    REGISTER_CUSTOMER_EMAIL_CONSENT_GIVEN_CHANGED
} from "./types";

import axios from "axios";
import { getFormData } from "../helpers";
import _ from "lodash";
import * as EmailValidator from 'email-validator';

export const registerCustomerEmailConsentGivenChanged = (email_consent_given_changed) => {

    return{
      type: REGISTER_CUSTOMER_EMAIL_CONSENT_GIVEN_CHANGED,
      payload: email_consent_given_changed
    };

};


export const registerCustomerSellerPermitChanged = (seller_permit) => {

    return{
      type: REGISTER_CUSTOMER_SELLER_PERMIT_CHANGED,
      payload: seller_permit
    };

};

export const registerStateCodeChanged = (state_code) => {

    return{
      type: REGISTER_STATE_CODE_CHANGED,
      payload: state_code
    };

};

export const registerCustomerCompanyZipCodeChanged = (company_zip_code) => {

    return{
      type: REGISTER_CUSTOMER_COMPANY_ZIP_CODE_CHANGED,
      payload: company_zip_code
    };

};

export const registerCustomerCompanyNameChanged = (company_name) => {

    return{
      type: REGISTER_CUSTOMER_COMPANY_NAME_CHANGED,
      payload: company_name
    };

};



export const registerCustomerCompanyStateChanged = (company_state) => {

    return{
      type: REGISTER_CUSTOMER_COMPANY_STATE_CHANGED,
      payload: company_state
    };

};

export const registerCustomerCompanyCityChanged = (company_city) => {

    return{
      type: REGISTER_CUSTOMER_COMPANY_CITY_CHANGED,
      payload: company_city
    };

};

export const registerCustomerCompanyAddressChanged = (company_address) => {

    return{
      type: REGISTER_CUSTOMER_COMPANY_ADDRESS_CHANGED,
      payload: company_address
    };

};

export const clearRegisterCustomerState = () => {

    return{
        type: CLEAR_REGISTER_CUSTOMER_STATE
    };

};

export const hideRegisterCustomerModal = () => {

    return{
        type: HIDE_REGISTER_CUSTOMER_MODAL
    };

};

export const registerCustomerPassword2Changed = (password2) => {

    return{
        type: REGISTER_CUSTOMER_PASSWORD2_CHANGED,
        payload: password2
    };

};

export const registerCustomerPasswordChanged = (password) => {

    return{
        type: REGISTER_CUSTOMER_PASSWORD_CHANGED,
        payload: password
    };

};

export const registerCustomerEmailChanged = (email) => {

    return{
        type: REGISTER_CUSTOMER_EMAIL_CHANGED,
        payload: email
    }

};

export const registerCustomerFullNameChanged = (full_name) => {

    return{
        type: REGISTER_CUSTOMER_FULL_NAME_CHANGED,
        payload: full_name
    }

};

export const registerCustomer = (
    history,
    email,
    password,
    password2,
    full_name,
    company_name,
    company_address,
    company_city,
    company_state,
    company_zip_code,
    seller_permit,
    email_consent_given,
    token
) => {

    return(dispatch) => {

        console.log("REGISTER CUSTOMER");

        console.log(email);

        console.log(password);

        console.log(password2);

        console.log(full_name);

        console.log("token", token);

        let is_valid = true;

        dispatch({type: CLEAR_REGISTER_CUSTOMER_ERRORS});



        if(!(EmailValidator.validate(email))){

            is_valid = false;

            dispatch({type: REGISTER_CUSTOMER_EMAIL_ERROR, payload: 'Email is invalid'});

        }

        if(password.length < 8){

            is_valid = false;

            dispatch({type: REGISTER_CUSTOMER_PASSWORD_ERROR, payload: 'Password must be at least 8 characters long'});

        }

        if(password2.length < 8){

            is_valid = false;

            dispatch({type: REGISTER_CUSTOMER_PASSWORD2_ERROR, payload: 'Password must be at least 8 characters long'});


        }


        if( (password.length >= 8 && password2.length >= 8 ) && (password !== password2)  ){

            is_valid = false;

            dispatch({type: REGISTER_CUSTOMER_PASSWORD2_ERROR, payload: 'Passwords do not match'});


        }

        if(_.isEmpty(full_name)){

            is_valid = false;

            dispatch({type: REGISTER_CUSTOMER_FULL_NAME_ERROR, payload: 'Full name cannot be empty'});

        }

        if(_.isEmpty(company_name)){

            is_valid = false;

            dispatch({type: REGISTER_CUSTOMER_COMPANY_NAME_ERROR, payload: 'Company name cannot be empty'});

        }

        if(_.isEmpty(company_address)){

            is_valid = false;

            dispatch({type: REGISTER_CUSTOMER_COMPANY_ADDRESS_ERROR, payload: 'Company address cannot be empty'});

        }

        if(_.isEmpty(company_city)){

            is_valid = false;

            dispatch({type: REGISTER_CUSTOMER_COMPANY_CITY_ERROR, payload: 'Company city cannot be empty'});

        }


        if(_.isEmpty(company_state)){

            is_valid = false;

            dispatch({type: REGISTER_CUSTOMER_COMPANY_STATE_ERROR, payload: 'Company state cannot be empty'});

        }




        if(_.isEmpty(company_zip_code)){

            is_valid = false;

            dispatch({type: REGISTER_CUSTOMER_COMPANY_ZIP_CODE_ERROR, payload: 'Company zip code cannot be empty'});

        }

        console.log("seller permit");

        console.log(seller_permit);

        if(seller_permit === undefined || seller_permit === null){

            is_valid = false;

            dispatch({type: REGISTER_CUSTOMER_SELLER_PERMIT_ERROR, payload: 'Seller permit cannot be empty'})

        }



        if(is_valid){

            dispatch({type: REGISTER_CUSTOMER});

            const config = {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "multipart/form-data"
                }
            };



            let bodyFormData = getFormData({
                email: email,
                password: password,
                full_name: full_name,
                token: token,
                company_name: company_name,
                company_address: company_address,
                company_city: company_city,
                company_zip_code: company_zip_code,
                company_state: company_state,
                seller_permit: seller_permit,
                email_consent_given: email_consent_given
            });


            axios.post(REGISTER_CUSTOMER_ROUTE, bodyFormData, config)
                .then(response => {

                    dispatch({type: REGISTER_CUSTOMER_COMPLETE});

                    const data = response.data;

                    console.log(data);

                    const success = data.success;

                    if(success){

                        dispatch({type: CLEAR_REGISTER_CUSTOMER_FORM});

                        dispatch({type: REGISTER_CUSTOMER_SUCCESS, payload: 'Registration complete. Please check your inbox to verify your email.'});


                    }


                }).catch(error => {


                console.log(error);

                dispatch({type: REGISTER_CUSTOMER_COMPLETE});


                let error_messages = [];

                const response = error.response;

                if(response !== undefined && response !== null && !_.isEmpty(response)){

                    const data = response.data;

                    if(data !== undefined && data !== null && !_.isEmpty(data)){

                        const errors = data.errors;

                        if(errors !== undefined && errors !== null && !_.isEmpty(errors)){

                            if(_.isArray(errors)){

                                _.map(errors, (error) => {

                                    error_messages.push(error);

                                });

                            }else{

                                const full_messages = errors.full_messages;

                                if(full_messages !== undefined && full_messages !== null && !_.isEmpty(full_messages)){

                                    _.map(full_messages, error => {

                                        error_messages.push(error);

                                    });


                                }else{

                                    error_messages.push("Could not register. Please try again");

                                }



                            }


                        }else{

                            error_messages.push("Could not register. Please try again");

                        }



                    }else{

                        error_messages.push("Could not register. Please try again");

                    }

                }else{

                    error_messages.push("Could not register. Please try again");

                }



                dispatch({type: REGISTER_CUSTOMER_FAILURE, payload: error_messages});



            });

        }


    };

};
