import {
    INITIALIZE_PROFILE_PAGE,
    INITIALIZE_PROFILE_PAGE_COMPLETE,
    CLEAR_PROFILE_PAGE_STATE,
    LOGOUT_SUCCESS,
    OPEN_CUSTOMER_CHANGE_EMAIL_MODAL,
    CLOSE_CUSTOMER_CHANGE_EMAIL_MODAL,
    NEW_CUSTOMER_EMAIL_CHANGED,
    CUSTOMER_CHANGE_EMAIL,
    CUSTOMER_CHANGE_EMAIL_COMPLETE,
    CUSTOMER_CHANGE_EMAIL_SUCCESS,
    CUSTOMER_CHANGE_EMAIL_FAILURE
} from "../actions/types";

const INITIAL_STATE = {
    initializing_page: false,
    full_name: '',
    email: '',
    phone_number_verified: null,
    phone_number: '',
    account_approved: '',
    company_address: '',
    company_city: '',
    company_name: '',
    company_state: '',
    company_zip_code: '',
    change_email_modal_visible: false,
    change_email_modal_success_message: '',
    change_email_modal_error_message: '',
    changing_email: false,
    new_email: ''
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CUSTOMER_CHANGE_EMAIL_FAILURE:
            return{
                ...state,
                change_email_modal_error_message: action.payload
            };
        case CUSTOMER_CHANGE_EMAIL_SUCCESS:
            return{
                ...state,
                change_email_modal_success_message: action.payload.change_email_modal_success_message,
                email: action.payload.email
            };
        case CUSTOMER_CHANGE_EMAIL_COMPLETE:
            return{
                ...state,
                changing_email: false
            };
        case CUSTOMER_CHANGE_EMAIL:
            return{
                ...state,
                change_email_modal_success_message: '',
                change_email_modal_error_message: '',
                changing_email: true
            };
        case NEW_CUSTOMER_EMAIL_CHANGED:
            return{
                ...state,
                new_email: action.payload
            };
        case CLOSE_CUSTOMER_CHANGE_EMAIL_MODAL:
            return{
                ...state,
                change_email_modal_visible: false,
                change_email_modal_success_message: '',
                change_email_modal_error_message: '',
                changing_email: false,
                new_email: ''
            };
        case OPEN_CUSTOMER_CHANGE_EMAIL_MODAL:
            return{
                ...state,
                change_email_modal_visible: true
            };
        case INITIALIZE_PROFILE_PAGE_COMPLETE:
            return{
                ...state,
                initializing_page: false,
                full_name: action.payload.full_name,
                email: action.payload.email,
                phone_number_verified:  action.payload.phone_number_verified,
                phone_number: action.payload.phone_number,
                account_approved: action.payload.account_approved,
                company_address: action.payload.company_address,
                company_city: action.payload.company_city,
                company_name: action.payload.company_name,
                company_state: action.payload.company_state,
                company_zip_code: action.payload.company_zip_code
            };
        case INITIALIZE_PROFILE_PAGE:
            return{
                ...state,
                initializing_page: true
            };
        case CLEAR_PROFILE_PAGE_STATE:
            return{
                ...state,
                ...INITIAL_STATE
            };
        case LOGOUT_SUCCESS:
            return{
                ...INITIAL_STATE
            };
        default:
            return state;
    }
};