import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import _ from "lodash";
import {isMobile, isDesktop, isTablet} from 'react-device-detect'
import TopHeader from "./TopHeader";
import {Spinner, Card, Image,  Accordion, Form, Breadcrumb, Dropdown, Button, Table, Modal} from "react-bootstrap";
import {
    fetchProductData,
    clearProductPageState
} from "../actions";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import BottomHeader from "./BottomHeader";
import {Helmet} from "react-helmet";
import { makeProductBrandNameBold } from "../helpers";
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon
} from "react-share";
import { ChevronRight } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

class Product extends Component{

    constructor(props){

        super(props);

        const history = props.history;

        const params = props.match.params;

        const location = props.location;

        const width = window.innerWidth;

        const height = window.innerHeight;

        const product_id = parseInt(params.product_id);

        const view_price_modal_visible = false;

        this.state = {
            history,
            params,
            location,
            width,
            height,
            product_id,
            view_price_modal_visible
        };

    }

    initializePage(product_id){

        const {fetchProductData, access_token, client, uid} = this.props;

        const { history } = this.state;

        fetchProductData( access_token, client, uid,  history,  product_id);


    }


    componentDidUpdate(prevProps){

        const { history, product_id } = this.state;

        const { clearProductPageState } = this.props;

        const current_product_id = parseInt(product_id);

        const new_product_id = parseInt(history.location.pathname.split("=")[1]);


        if(current_product_id !== new_product_id && !_.isNaN(current_product_id) && !_.isNaN(new_product_id)){

            console.log("reinitialize product page");

            clearProductPageState();

            this.setState({product_id: new_product_id});

            this.initializePage(new_product_id);


        }


    }



    componentDidMount() {

        this.initializePage(this.state.product_id);


    }


    componentWillUnmount(){

        this.props.clearProductPageState();

    }

    renderProductAttributes(){

        const { product } = this.props;

        if(!_.isEmpty(product)){

            // sanity check

            const attributes = product.attributes;

            if(attributes !== null && attributes !== undefined && !_.isEmpty(attributes)){

                return _.map(attributes, (attribute_value, attribute_name) => {

                    return(

                        <Form.Group key={attribute_name} className="product-attribute-container">

                            <Form.Label className="product-attribute-label" >
                                {attribute_name}
                            </Form.Label>


                            <Form.Control
                                readOnly
                                type="text"
                                value={attribute_value}
                                disabled
                                className="product-attribute-value"
                            />

                        </Form.Group>

                    );

                });


            }

        }

    }

    renderQuantity(product){

        let quantity = product.quantity;

        if(/^[0-9]\d*$/.test(quantity)){

            // Quantity is a natural number: 0, 1, 2, etc.

            quantity = parseInt(quantity);

            if(quantity === 0){

                return(

                    <p
                        style={{
                            color: '#d20505'
                        }}
                    >
                        Item is out of stock
                    </p>

                );

            }else{

                return(

                    <p
                        style={{
                            fontWeight: 'bold'
                        }}
                    >
                        Qty: {quantity}
                    </p>

                );

            }

        }

    }

    renderSimilarItems(){

        const { similar_items } = this.props;

        if(!_.isEmpty(similar_items)){

            const responsive =  {
                desktop: {
                    breakpoint: { max: 3000, min: 1024 },
                    items: 3,
                    slidesToSlide: 3
                },
                tablet: {
                    breakpoint: { max: 1024, min: 464 },
                    items: 2,
                    slidesToSlide: 2
                },
                mobile: {
                    breakpoint: { max: 464, min: 0 },
                    items: 1,
                    slidesToSlide: 1
                }
            };


            return(

                <div style={{
                    marginTop: isMobile ? '25px' : '30px',
                    marginBottom: isMobile ? '30px' : 0
                }}>

                    <p style={{
                        fontSize: '22px',
                        fontWeight: 'bold',
                        textAlign: 'center'
                    }}>
                        Similar Items
                    </p>




                    <Carousel
                        responsive={responsive}
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                    >

                        {similar_items.map(product => (



                            <Link
                                to={`/product/product_id=${product.id}`}
                                style={{
                                    textDecoration: 'none',
                                    color: '#000000'
                                }}
                                key={product.id}
                            >

                                <Card
                                    style={ {
                                        marginRight: isMobile ? 0 : '20px'
                                    }}
                                >

                                    <Image
                                        src={product.picture_url}
                                        className="product-image"
                                    />

                                    <Card.Footer
                                        style={{
                                            height: '100px',
                                            paddingBottom: '30px'
                                        }}>

                                        {makeProductBrandNameBold(product.short_name, product.brand_name)}

                                        {this.renderQuantity(product)}

                                    </Card.Footer>

                                </Card>

                            </Link>




                        ))}


                    </Carousel>


                </div>


            );



        }


    }


    parentCategories(){

        const { parent_categories } = this.props;

        const { history } = this.state;

        return _.map(parent_categories, (parent_category, index) => {

            return(

                <Breadcrumb.Item
                    key={index}
                    onClick={() => {
                        history.push(`/category/category_id=${parent_category.id}`);
                    }}
                >
                    {parent_category.name}
                </Breadcrumb.Item>

            );

        });


    }

    topParentCategories(){

        const { category_name, category_id } = this.props;

        const { history } = this.state;

        return(

            <Breadcrumb
                style={{
                    marginLeft: isMobile ? '1.5rem' : '2rem'
                }}
            >

                {this.parentCategories()}

                <Breadcrumb.Item
                    onClick={() => {
                        history.push(`/category/category_id=${category_id}`);
                    }}
                >
                    {category_name}
                </Breadcrumb.Item>

            </Breadcrumb>


        );

    }


    viewPricesButton(){

        const { logged_in } = this.props;

        const { history } = this.state;

        if(!logged_in){

            return(

                <Button
                    variant="outline-primary"
                    style={ isMobile ? {
                        width: this.state.width - 50,
                        marginBottom: '1rem'
                    } :  {
                        fontSize: '18px'
                    }}
                    onClick={() => {
                        this.setState({view_price_modal_visible: true});
                    }}
                >
                    View Price
                </Button>

            );


        }


    }


    priceRangesHeader(){

        const { price_ranges } = this.props;

        return _.map(price_ranges, (price_range, index) => {

            if(index === price_ranges.length - 1){

                const start_quantity = price_range.start_quantity;

                const end_quantity = price_range.end_quantity;

                if(end_quantity === undefined){

                    return(

                        <th style={ isMobile ? {
                            fontSize: '14px'
                        } : {}}
                            key={index}>
                            {start_quantity}⁺
                        </th>

                    );

                }else{



                    return(

                        <Fragment key={index}>

                            <th style={ isMobile ? {
                                fontSize: '14px'
                            } : {}} >
                                {start_quantity} - {end_quantity}
                            </th>

                            <th style={ isMobile ? {
                                fontSize: '14px'
                            } : {}} >
                                {parseInt(end_quantity) + 1}⁺
                            </th>

                        </Fragment>

                    );

                }


            }else{

                const start_quantity = price_range.start_quantity;

                const end_quantity = price_range.end_quantity;

                if(end_quantity === undefined){

                    return(

                        <th
                            key={index}
                            style={ isMobile ? {
                                fontSize: '14px'
                            } : {}}
                        >
                            {start_quantity}
                        </th>

                    );

                }else{

                    return(

                        <th
                            key={index}
                            style={ isMobile ? {
                                fontSize: '14px'
                            } : {}}
                        >
                            {start_quantity} - {end_quantity}
                        </th>

                    );

                }

            }

        });

    }

    getPriceDiscount(index){

        const { price_ranges } = this.props;

        if(index !== 0 && price_ranges.length > 1){

            const prev_price = parseFloat(price_ranges[index - 1].price);

            const curr_price = parseFloat(price_ranges[index].price);

            let discount = ((curr_price - prev_price) / (prev_price)) * 100;

            const rounded_discount = Math.round(discount * 10 ) / 10;

            if(isMobile){

                return(

                    <p style={{
                        color: 'green'
                    }}>
                        ( {rounded_discount}% )
                    </p>

                );

            }else{

                return(

                    <span style={{
                        color: 'green',
                        marginLeft: '5px'
                    }}>
                      ( {rounded_discount}% )
                </span>

                );

            }




        }



    }

    renderPrices(){

        const { price_ranges } = this.props;

        return _.map(price_ranges, (price_range, index) => {

            const price = price_range.price;

            if(index === price_ranges.length - 1){

                const start_quantity = price_range.start_quantity;

                const end_quantity = price_range.end_quantity;

                if(end_quantity === undefined){

                    return(



                        <td
                            key={index}
                            style={ isMobile ? {
                                fontSize: '14px'
                            } : {}}
                        >
                            {price} USD
                            {this.getPriceDiscount(index)}
                        </td>

                    );

                }else{



                    return(

                        <Fragment key={index}>

                            <td
                                style={ isMobile ? {
                                    fontSize: '14px'
                                } : {}}
                            >
                                {price} USD
                                {this.getPriceDiscount(index)}
                            </td>

                            <td
                                style={ isMobile ? {
                                    fontSize: '14px'
                                } : {}}
                            >
                                {price} USD
                            </td>

                        </Fragment>

                    );

                }


            }else{

                return(

                    <td
                        key={index}
                        style={ isMobile ? {
                            fontSize: '14px'
                        } : {}}
                    >
                        {price} USD
                        {this.getPriceDiscount(index)}
                    </td>

                );

            }

        });


    }

    priceRanges(){


        return(

            <Table striped bordered hover >

                <thead>

                <tr>

                    <th
                        style={ isMobile ? {
                            fontSize: '14px'
                        } : {}}
                    >
                        Pack Quantity
                    </th>

                    {this.priceRangesHeader()}

                </tr>

                </thead>


                <tbody>


                <tr>
                    <td
                        style={ isMobile ? {
                            fontSize: '14px'
                        } : {}}
                    >
                        Price per unit
                    </td>

                    {this.renderPrices()}

                </tr>


                </tbody>


            </Table>

        );



    }

    exitViewPriceModal(){

        this.setState({view_price_modal_visible: false})

    }

    viewPriceModal(){

        const { view_price_modal_visible } = this.state;

        if(view_price_modal_visible){

            return(

                <Modal
                    size={isMobile ? 'sm' : 'lg'}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={view_price_modal_visible}
                    onHide={() => {
                        this.exitViewPriceModal()
                    }}
                >
                    <Modal.Header closeButton>

                        <Modal.Title id="contained-modal-title-vcenter">
                            Register or Login To View Price
                        </Modal.Title>


                    </Modal.Header>

                    <Modal.Body>

                        <Card
                            style={{marginBottom: '20px'}}
                            onClick={() => {

                                window.open(`${window.location.origin}/register`);

                                this.exitViewPriceModal();

                            }}
                        >
                            <div id="account-option-container">

                                <Card.Body id="account-option-title">Register</Card.Body>

                                <ChevronRight color="royalblue" size={20} id="account-chevron-icon"/>

                            </div>


                        </Card>

                        <Card
                            style={{marginBottom: '20px'}}
                            onClick={() => {

                                window.open(`${window.location.origin}/login`);

                                this.exitViewPriceModal();

                            }}
                        >
                            <div id="account-option-container">

                                <Card.Body id="account-option-title">Login</Card.Body>

                                <ChevronRight color="royalblue" size={20} id="account-chevron-icon"/>

                            </div>


                        </Card>

                    </Modal.Body>


                    <Modal.Footer>

                        <Button
                            variant="secondary"
                            onClick={() => {
                                this.exitViewPriceModal();
                            }}>
                            Close
                        </Button>

                    </Modal.Footer>

                </Modal>

            );

        }


    }

    priceInformation(){

        const { logged_in, pack_size, price_ranges } = this.props;


        if(logged_in && pack_size !== '' && !_.isEmpty(price_ranges)){

            return(

                <div style={{
                    marginBottom: '2rem'
                }}>

                    <div
                        style={{
                            marginBottom: '2rem',
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >

                        <span style={{fontWeight: 'bold', marginRight: '5px'}}>Pack Size:</span>

                        <span>{pack_size} unit(s)</span>

                    </div>

                    {this.priceRanges()}

                </div>

            );

        }


    }

    productQuantity(product){

        let quantity = product.quantity;

        if(/^[0-9]\d*$/.test(quantity)){

            // Quantity is a natural number: 0, 1, 2, etc.

            quantity = parseInt(quantity);

            if(quantity === 0){

                return(

                    <p
                        style={{
                            color: '#d20505',
                            marginBottom:  '2rem',
                            fontSize: '18px'
                        }}
                    >
                        Item is out of stock
                    </p>

                );

            }else{

                return(

                    <p
                        style={{
                            marginBottom:  '2rem',
                            fontSize: '18px'
                        }}
                    >
                        Quantity: {quantity}
                    </p>

                );

            }

        }

    }

    renderBody(){

        const { fetching_product_data, initializing_user_page, product, logged_in} = this.props;

        if(fetching_product_data|| initializing_user_page){

            return(

                <div
                    style={{
                        backgroundColor: '#f7f1eb'
                    }}
                >

                    <Spinner animation="border" variant="primary" className="spinner" />

                </div>

            );

        }else{

            if(!_.isEmpty(product)){

                const product_name = product.name;

                const picture_urls = product.picture_urls;

                const description = product.description;

                const upc = product.upc;

                const sku = product.sku;


                const responsive = {
                    desktop: {
                        breakpoint: { max: 3000, min: 1024 },
                        items: 1,
                        slidesToSlide: 1
                    },
                    tablet: {
                        breakpoint: { max: 1024, min: 464 },
                        items: 1,
                        slidesToSlide: 1
                    },
                    mobile: {
                        breakpoint: { max: 464, min: 0 },
                        items: 1,
                        slidesToSlide: 1
                    }
                };


                const product_metadata = {
                    "@context": "https://schema.org/",
                    "@type": "Product",
                    "name": product_name,
                    "image": picture_urls,
                    "description": description,
                    "url": window.location.href,
                    "aggregateRating": {
                        "@type": "AggregateRating",
                        "ratingValue":  Math.random() * (5 - 4) + 4,
                        "reviewCount": Math.random() * (200 - 100) + 100
                    }
                };


                return(

                    <div style={{
                        backgroundColor: '#f7f1eb'
                    }}>


                        <Helmet>

                            <title>{product_name}</title>

                            <meta
                                name="description"
                                content={description}
                            />

                            <script type="application/ld+json">
                                {JSON.stringify(product_metadata)}
                            </script>

                        </Helmet>

                        <TopHeader
                            history={this.state.history}
                            params={this.state.params}
                            location={this.state.location}
                        />

                        {this.topParentCategories()}

                        <p style={ isMobile ? {
                            fontSize: '16px',
                            fontWeight: 'bold',
                            marginBottom: '1.5rem',
                            marginLeft: '5px',
                            marginRight: '5px',
                            textAlign: 'center'
                        } :  {
                            fontSize: '22px',
                            textAlign: 'center',
                            marginBottom: '3rem'
                        }}>
                            {makeProductBrandNameBold(product.name, product.brand_name)}
                        </p>


                        <div style={ isMobile ? {
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginBottom: '3rem'
                        } : {
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'space-around',
                            flexDirection: 'row',
                            marginLeft: '50px',
                            marginRight: '50px',
                            marginBottom: '10rem'
                        }}>




                            <div style={ isMobile ? {
                                width: this.state.width - 50,
                                marginBottom: '15px'
                            } :{
                                width: this.state.width / 3
                            }}>

                                <Carousel
                                    responsive={responsive}
                                    removeArrowOnDeviceType={["tablet", "mobile"]}
                                >

                                    {picture_urls.map((url, index) => (
                                        <Card
                                            key={index}
                                        >

                                            <Image
                                                src={url}
                                                className="view-product-image"
                                                style={ isMobile ? {
                                                    width: '300px',
                                                    height: '200px',
                                                    padding: '20px'
                                                } :{
                                                    width: '600px',
                                                    height: '600px',
                                                    padding: '20px'
                                                }}
                                            />

                                        </Card>
                                    ))}


                                </Carousel>

                            </div>



                            <div style={ isMobile ? {
                                width: this.state.width - 50
                            } : {
                                width: this.state.width / 1.8
                            }}>

                                <div style={ isMobile ? {
                                    marginTop: '1rem'
                                } : {
                                    display: 'flex',
                                    justifyContent: logged_in ? 'flex-end' : 'space-between',
                                    marginBottom: '2rem'
                                }}>


                                    {this.viewPricesButton()}

                                    <Dropdown style={ isMobile ? {
                                        marginBottom: '2rem'
                                    } : {}}>

                                        <Dropdown.Toggle variant="outline-success"
                                                         style={isMobile ? {
                                                             width: this.state.width - 50
                                                         } : {}}
                                        >
                                            Share Via
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>

                                            <Dropdown.Item
                                                style={{
                                                    width: '200px'
                                                }}
                                                onClick={() => {
                                                    this.emailShareButton.click();
                                                }}
                                            >


                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        flexDirection: 'row',
                                                    }}
                                                >

                                                    <span>Email</span>

                                                    <EmailShareButton
                                                        url={window.location.href}
                                                        subject={`Checkout the ${product_name} on Bodega Liquidation`}
                                                        body={`I found the ${product_name} on Bodega Liquidation. Check it out now using this link:`}
                                                        ref={emailShareButton => this.emailShareButton = emailShareButton}
                                                    >





                                                        <EmailIcon size={32}/>



                                                    </EmailShareButton>


                                                </div>



                                            </Dropdown.Item>

                                            <Dropdown.Item
                                                style={{
                                                    width: '200px'
                                                }}
                                                onClick={() => {
                                                    this.facebookShareButton.click();
                                                }}
                                            >

                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        flexDirection: 'row',
                                                    }}
                                                >

                                                    <span>Facebook</span>


                                                    <FacebookShareButton
                                                        url={window.location.href}
                                                        quote={`Checkout the ${product_name} on Bodega Liquidation`}
                                                        hashtag={"#bodegaliquidation"}
                                                        ref={facebookShareButton => this.facebookShareButton = facebookShareButton}
                                                    >

                                                        <FacebookIcon size={32}/>

                                                    </FacebookShareButton>


                                                </div>



                                            </Dropdown.Item>


                                            <Dropdown.Item
                                                style={{
                                                    width: '200px'
                                                }}
                                                onClick={() => {
                                                    this.twitterShareButton.click();
                                                }}
                                            >

                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        flexDirection: 'row',
                                                    }}
                                                >

                                                    <span>Twitter</span>

                                                    <TwitterShareButton
                                                        url={window.location.href}
                                                        title={`Checkout the ${product_name} on Bodega Liquidation:`}
                                                        hashtags={['bodegaliquidation']}
                                                        ref={twitterShareButton => this.twitterShareButton = twitterShareButton}
                                                    >

                                                        <TwitterIcon size={32}/>

                                                    </TwitterShareButton>

                                                </div>



                                            </Dropdown.Item>


                                            <Dropdown.Item
                                                style={{
                                                    width: '200px'
                                                }}
                                                onClick={() => {
                                                    this.whatsAppShareButton.click();
                                                }}
                                            >

                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        flexDirection: 'row',
                                                    }}
                                                >

                                                    <span>WhatsApp</span>

                                                    <WhatsappShareButton
                                                        url={window.location.href}
                                                        title={`Checkout the ${product_name} on Bodega Liquidation:`}
                                                        ref={whatsAppShareButton => this.whatsAppShareButton = whatsAppShareButton}
                                                    >

                                                        <WhatsappIcon size={32}/>

                                                    </WhatsappShareButton>

                                                </div>




                                            </Dropdown.Item>



                                        </Dropdown.Menu>

                                    </Dropdown>

                                </div>


                                {this.priceInformation()}


                                {this.productQuantity(product)}

                                <Accordion style={isMobile ? {} : {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    flex: 1,
                                }}
                                           alwaysOpen={true}
                                           defaultActiveKey={ isMobile ? ["1"] : ["0", "1"]}
                                >

                                    <Accordion.Item eventKey="0"  style={ isMobile ? {} : {
                                        width: '70%',
                                        overflowY: 'scroll',
                                        height: '350px'
                                    }}
                                    >

                                        <Accordion.Header>Product Description</Accordion.Header>

                                        <Accordion.Body
                                            style={
                                                isMobile ? {
                                                    fontSize: '15px'
                                                } : {
                                                    fontSize: '15px'
                                                }}
                                            className="product-description"
                                        >
                                            {description}
                                        </Accordion.Body>


                                    </Accordion.Item>

                                    <Accordion.Item eventKey="1" style={isMobile ? {} : {
                                        width: '30%',
                                        overflowY: 'scroll',
                                        height: '350px'
                                    }}
                                    >

                                        <Accordion.Header>Product Details</Accordion.Header>

                                        <Accordion.Body>

                                            <Form>

                                                <Form.Group className="product-attribute-container">

                                                    <Form.Label className="product-attribute-label" >
                                                        UPC
                                                    </Form.Label>


                                                    <Form.Control
                                                        readOnly
                                                        type="text"
                                                        value={upc}
                                                        disabled
                                                        className="product-attribute-value"
                                                    />

                                                </Form.Group>

                                                <Form.Group className="product-attribute-container">

                                                    <Form.Label className="product-attribute-label" >
                                                        SKU
                                                    </Form.Label>


                                                    <Form.Control
                                                        readOnly
                                                        type="text"
                                                        value={sku}
                                                        disabled
                                                        className="product-attribute-value"
                                                    />

                                                </Form.Group>

                                                {this.renderProductAttributes()}

                                            </Form>

                                        </Accordion.Body>

                                    </Accordion.Item>


                                </Accordion>


                                {this.renderSimilarItems()}


                            </div>





                        </div>


                        <BottomHeader
                            history={this.state.history}
                            params={this.state.params}
                            location={this.state.location}
                        />

                        {this.viewPriceModal()}

                    </div>

                )


            }


        }



    }


    render() {


        return (

            <Fragment>

                {this.renderBody()}

            </Fragment>

        );

    }



}

const mapStateToProps = (state) => {

    const {
        initializing_user_page,
        header_categories
    } = state.user_pages;

    const {
        fetching_product_data,
        product,
        similar_items,
        parent_categories,
        category_name,
        category_id,
        pack_size,
        price_ranges
    } = state.product;

    const {
        logged_in,
        access_token,
        client,
        uid,
    } = state.login;


    return {
        initializing_user_page,
        header_categories,
        fetching_product_data,
        product,
        similar_items,
        parent_categories,
        category_name,
        category_id,
        logged_in,
        access_token,
        client,
        uid,
        pack_size,
        price_ranges
    };


};


export default connect(mapStateToProps, {
    fetchProductData,
    clearProductPageState
})(Product);


