import React, {Component} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import {getHeaderCategories} from "./actions";
import {connect} from 'react-redux';
import Landing from "./components/Landing";
import SearchProduct from "./components/SearchProduct";
import Category from "./components/Category";
import Product from "./components/Product";
import CategoryProducts from "./components/CategoryProducts";
import Register from "./components/Register";
import ResendConfirmation from "./components/ResendConfirmation";
import ResendUnlock from "./components/ResendUnlock";
import SendResetLink from "./components/SendResetLink";
import Login from "./components/Login";
import ResetPassword from "./components/ResetPassword";
import Profile from "./components/Profile";
import PhoneNumber from "./components/PhoneNumber";


import NotFound from "./components/NotFound";


class AppRoutes extends Component{

    constructor(props){
        super(props);
    }

    componentDidMount(){
        this.props.getHeaderCategories();
    }

    render(){

        return(

            <Router>

                <Switch>

                    <Route exact path="/" component={Landing} />

                    <Route exact path="/search-product" component={SearchProduct} />

                    <Route exact path="/category/category_id=:category_id" component={Category} />

                    <Route exact path="/product/product_id=:product_id" component={Product} />

                    <Route exact path="/category-products/category_id=:category_id" component={CategoryProducts} />

                    <Route exact path="/register" component={Register} />

                    <Route exact path="/resend-confirmation" component={ResendConfirmation} />

                    <Route exact path="/resend-unlock" component={ResendUnlock} />

                    <Route exact path="/send-reset-link" component={SendResetLink} />

                    <Route exact path="/login" component={Login} />

                    <Route exact path="/reset-password/access_token=:access_token&client=:client&uid=:uid&reset_password_token=:reset_password_token" component={ResetPassword} />

                    <Route exact path="/profile" component={Profile} />

                    <Route exact path="/phone-number" component={PhoneNumber} />

                    <Route path="*" component={NotFound} />

                </Switch>

            </Router>

        );

    }

}


export default connect(null, {
    getHeaderCategories
})(AppRoutes);
