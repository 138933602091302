import {
    LOGOUT_SUCCESS,
    SEND_SMS_VERIFICATION_ROUTE,
    SEND_SMS_VERIFICATION,
    SEND_SMS_VERIFICATION_COMPLETE,
    SEND_SMS_VERIFICATION_SUCCESS,
    SEND_SMS_VERIFICATION_FAILURE,
    EXIT_SMS_VERIFICATION_MODAL,
    OPEN_SMS_VERIFICATION_MODAL,
    CLEAR_PHONE_NUMBER_STATE,
    CHECK_SMS_VERIFICATION_CODE,
    CHECK_SMS_VERIFICATION_CODE_COMPLETE,
    CHECK_SMS_VERIFICATION_CODE_FAILURE,
    CHECK_SMS_VERIFICATION_CODE_ROUTE,
    RESEND_SMS_VERIFICATION_CODE,
    RESEND_SMS_VERIFICATION_CODE_COMPLETE,
    RESEND_SMS_VERIFICATION_CODE_FAILURE
} from "./types";

import axios from "axios";
import { getFormData } from "../helpers";
import _ from "lodash";

export const resendSmSVerificationCode = (history, access_token, client, uid, number) => {

    return(dispatch) => {

        dispatch({type: RESEND_SMS_VERIFICATION_CODE});

        const config = {
            headers: {
                "Accept": "application/json",
                "access_token": access_token,
                "client": client,
                "uid": uid
            }
        };

        let bodyFormData = getFormData({
            number: number
        });

        axios.post(SEND_SMS_VERIFICATION_ROUTE, bodyFormData, config)
            .then(response => {

                dispatch({type: RESEND_SMS_VERIFICATION_CODE_COMPLETE});

                const data = response.data;

                const success = data.success;

                console.log(data);

                if(!success){

                    const error_code = data.error_code;

                    const message = data.message;

                    if(error_code === 0){

                        dispatch({type: RESEND_SMS_VERIFICATION_CODE_FAILURE, payload: message});

                    }else if(error_code === 1){

                        dispatch({type: RESEND_SMS_VERIFICATION_CODE_FAILURE, payload: message});

                    }else if(error_code === 2){

                        dispatch({type: RESEND_SMS_VERIFICATION_CODE_FAILURE, payload: message});

                    }else{

                        dispatch({type: RESEND_SMS_VERIFICATION_CODE_FAILURE, payload: 'An error occurred. Please try again later.'});

                    }

                }


            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });

    };


};

export const checkSmSVerificationCode = (history, access_token, client, uid, number, code) => {

    return(dispatch) => {

        dispatch({type: CHECK_SMS_VERIFICATION_CODE});

        const config = {
            headers: {
                "Accept": "application/json",
                "access_token": access_token,
                "client": client,
                "uid": uid
            }
        };

        let bodyFormData = getFormData({
            number: number,
            code: code
        });

        axios.post(CHECK_SMS_VERIFICATION_CODE_ROUTE, bodyFormData, config)
            .then(response => {

                dispatch({type: CHECK_SMS_VERIFICATION_CODE_COMPLETE});

                const data = response.data;

                const success = data.success;

                if(success){

                    history.push("/profile");

                }else{

                    const message = data.message;

                    dispatch({type: CHECK_SMS_VERIFICATION_CODE_FAILURE, payload: message});

                }


            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });



    };

};

export const clearPhoneNumberState = () => {

    return{
      type: CLEAR_PHONE_NUMBER_STATE
    };

};


export const exitSmsVerificationModal = () => {

    return{
      type: EXIT_SMS_VERIFICATION_MODAL
    };

};

export const sendSmsVerificationCode = (history, access_token, client, uid, number) => {

    return(dispatch) => {

        dispatch({type: SEND_SMS_VERIFICATION});

        const config = {
            headers: {
                "Accept": "application/json",
                "access_token": access_token,
                "client": client,
                "uid": uid
            }
        };

        let bodyFormData = getFormData({
            number: number
        });

        axios.post(SEND_SMS_VERIFICATION_ROUTE, bodyFormData, config)
            .then(response => {

                dispatch({type: SEND_SMS_VERIFICATION_COMPLETE});

                const data = response.data;

                const success = data.success;

                console.log(data);

                if(success){

                    dispatch({type: SEND_SMS_VERIFICATION_SUCCESS});

                }else{

                    const error_code = data.error_code;

                    const message = data.message;

                    if(error_code === 0){

                        dispatch({type: SEND_SMS_VERIFICATION_FAILURE, payload: message});

                    }else if(error_code === 1){

                        dispatch({type: OPEN_SMS_VERIFICATION_MODAL});

                    }else if(error_code === 2){

                        dispatch({type: SEND_SMS_VERIFICATION_FAILURE, payload: message});

                    }else{

                        dispatch({type: SEND_SMS_VERIFICATION_FAILURE, payload: 'An error occurred. Please try again later'});

                    }

                }


            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });

    };

};