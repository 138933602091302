import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "./App.css";
import AppRoutes from "./AppRoutes";
import reducers from "./reducers";
import {createStore, applyMiddleware, compose} from 'redux';
import ReduxThunk from "redux-thunk";
import { Provider } from 'react-redux';
import { persistStore} from 'redux-persist' // only add persist code when persisted reducer present, else app wont render
import { PersistGate } from 'redux-persist/integration/react'; // only add persist code when persisted reducer present, else app wont render
import {Helmet} from "react-helmet";
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import {
    LOGIN_CUSTOMER_SUCCESS,
    LOGOUT_SUCCESS
} from "./actions/types";


export default function App() {

    const middleware = [
        createStateSyncMiddleware({
            whitelist: [LOGIN_CUSTOMER_SUCCESS, LOGOUT_SUCCESS]
        }),
       ReduxThunk
    ];

    const store = createStore(reducers, {}, applyMiddleware(...middleware));

    initMessageListener(store);

    const persistor = persistStore(store);

    return(

        <Provider store={store}>

            <Helmet>

                <title>Bodega Liquidation</title>

                <meta
                    name="description"
                    content="Bodega Liquidation is the best wholesale supplier in Houston. We are your best wholesale option! Come visit us for the best deals, discounts and offers."
                />

            </Helmet>


            <PersistGate loading={null} persistor={persistor}>

                <AppRoutes/>

            </PersistGate>



        </Provider>

    );


}
