import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import _ from "lodash";
import {isMobile, isDesktop, isTablet} from 'react-device-detect'
import TopHeader from "./TopHeader";
import {Spinner, Card, Image, Button, OverlayTrigger, Popover} from "react-bootstrap";
import {
    getCategoryProducts,
    clearCategoryProductsState,
    loadMoreCategoryProducts,
    exitCategoryProductsPage
} from "../actions";
import BottomHeader from "./BottomHeader";
import {Helmet} from "react-helmet";
import { makeProductBrandNameBold } from "../helpers";
import { Link } from "react-router-dom";
import {ScrollTop} from "./ScrollTop";


class CategoryProducts extends Component{

    constructor(props){

        super(props);

        const history = props.history;

        const params = props.match.params;

        const location = props.location;

        const width = window.innerWidth;

        const height = window.innerHeight;

        const category_id = parseInt(params.category_id);

        this.state = {
            history,
            params,
            location,
            width,
            height,
            category_id
        };

    }



    componentDidUpdate(prevProps){



        const { history, category_id } = this.state;

        const {  getCategoryProducts } = this.props;

        const current_category_id = parseInt(category_id);

        const new_category_id = parseInt(history.location.pathname.split("=")[1]);
        
        if(current_category_id !== new_category_id && !_.isNaN(current_category_id) && !_.isNaN(new_category_id) ){

            console.log("reinitialize category products page");

            this.setState({category_id: new_category_id});

            getCategoryProducts(new_category_id, 15, history);



        }


    }


    componentWillUnmount(){

        console.log("executing componentwillunmount");

        this.props.exitCategoryProductsPage(window.pageXOffset, window.pageYOffset);

    }

    componentDidMount(){


        const {getCategoryProducts,  previous_category_id, xoffset, yoffset} = this.props;


        const { history, category_id } = this.state;



        if( category_id !== previous_category_id){

            getCategoryProducts(category_id, 15, history);

        }else{

            if(xoffset !== null && yoffset !== null){

                window.scrollTo(xoffset, yoffset);

            }




        }


    }


    getProducts(){

        let products = this.props.products;

        if(products.length % 3 === 0) {

            return products;

        }else{

            let nearest_multiple_3 = products.length;

            while(nearest_multiple_3 % 3 !== 0){

                products.push({});

                nearest_multiple_3 += 1;


            }

            return products;

        }



    }


    renderPopOver(product){

        return(

            <Popover id={`popover-${product.id}`}>

                <Popover.Header as="h3">
                    {product.name}
                </Popover.Header>

                <Popover.Body style={{
                    whiteSpace: 'pre-line'
                }}>
                    { product.short_description.length >= 300 ? `${product.short_description}...` : product.short_description }
                </Popover.Body>

            </Popover>

        );

    }

    renderQuantity(product){

        let quantity = product.quantity;

        if(/^[0-9]\d*$/.test(quantity)){

            // Quantity is a natural number: 0, 1, 2, etc.

            quantity = parseInt(quantity);

            if(quantity === 0){

                return(

                    <p
                        style={{
                            color: '#d20505'
                        }}
                    >
                        Item is out of stock
                    </p>

                );

            }else{

                return(

                    <p
                        style={{
                            fontWeight: 'bold'
                        }}
                    >
                        Qty: {quantity}
                    </p>

                );

            }

        }

    }


    renderProductsList(){

        const products = isMobile ? this.props.products : this.getProducts();

        return _.map(products, (product, index) => {

            const position = index + 1;

            if(_.isEmpty(product)){

                return(

                    <Card
                        key={index}
                        style={{
                            flexBasis: this.state.width / 4,
                            margin: '15px',
                            visibility: 'hidden'
                        }}
                    />


                );


            }else{

                return(


                    <OverlayTrigger
                        key={index}
                        trigger={'hover' || 'focus'}
                        placement={ position % 3 === 0 ? 'left' : 'right'}
                        overlay={this.renderPopOver(product)}
                    >

                        <Link
                            to={`/product/product_id=${product.id}`}
                            style={{
                                textDecoration: 'none',
                                color: '#000000'
                            }}
                        >

                            <Card
                                style={
                                    isMobile ?
                                        {
                                            width: this.state.width - 35,
                                            margin: '15px'
                                        } :
                                        {
                                            width: this.state.width / 4,
                                            margin: '15px'
                                        }}
                            >





                                <Card.Img variant="top" className="product-image" src={product.main_picture_url} />






                                <Card.Footer style={{
                                    height: '100px',
                                    paddingBottom: '30px'
                                }}>




                                    {makeProductBrandNameBold(product.short_name, product.brand_name)}




                                    {this.renderQuantity(product)}

                                </Card.Footer>



                            </Card>

                        </Link>









                    </OverlayTrigger>




                );



            }



        });


    }


    loadMoreButton(){

        const {products_quantity,  loadMoreCategoryProducts,  loading_category_products} = this.props;

        const { history, category_id } = this.state;

        if(loading_category_products){

            return(

                <div style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>

                    <Spinner animation="border" variant="primary" />


                </div>


            );

        }else{

            return(

                <Button
                    variant="success"
                    style={{
                        width: isMobile ? '85%' : '10%',
                        alignSelf: 'center',
                        borderRadius: '20px'
                    }}
                    onClick={() => {
                        loadMoreCategoryProducts(category_id, products_quantity, history);
                        window.scrollBy(0, -0.001);
                    }}
                >
                    Load More
                </Button>

            );

        }



    }


    renderProducts(){


        const {products_quantity,  loadMoreCategoryProducts} = this.props;

        const { history, category_id } = this.state;


        return(

            <div style={ isMobile ? {
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '3rem',
            } : {
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '4rem',
            }}>

                <div style={ isMobile ? {
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center',
                    flexDirection: 'column',
                    marginBottom: '2rem'
                } : {
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    marginBottom: '2rem'
                }}>

                    {this.renderProductsList()}

                </div>


                {this.loadMoreButton()}


            </div>



        );

    }

    renderBody(){

        const { fetching_category_products, initializing_user_page, category_name } = this.props;

        if(fetching_category_products || initializing_user_page){

            return(

                <div
                    style={{
                        backgroundColor: '#f7f1eb'
                    }}
                >

                    <Spinner animation="border" variant="primary" className="spinner" />

                </div>

            );

        }else{

            return(

                <div
                    style={{
                        backgroundColor: '#f7f1eb'
                    }}
                >

                    <Helmet>

                        <title>{category_name}</title>

                        <meta
                            name="description"
                            content={`Bodega Liquidation: ${category_name}`}
                        />

                    </Helmet>

                    <TopHeader
                        history={this.state.history}
                        params={this.state.params}
                        location={this.state.location}
                    />


                    <p style={{
                        textAlign: 'center',
                        fontSize: isMobile ? '30px' : '38px'
                    }}>
                        {category_name}
                    </p>

                    {this.renderProducts()}




                    <BottomHeader
                        history={this.state.history}
                        params={this.state.params}
                        location={this.state.location}
                    />


                </div>

            )
        }



    }

    render() {


        return (

            <Fragment>

                {this.renderBody()}


                <ScrollTop/>




            </Fragment>

        );

    }


}

const mapStateToProps = (state) => {

    const {
        initializing_user_page
    } = state.user_pages;

    const {
        fetching_category_products,
        category_name,
        products,
        products_quantity,
        loading_category_products,
        previous_category_id,
        xoffset,
        yoffset
    } = state.category_products;


    return {
        initializing_user_page,
        fetching_category_products,
        category_name,
        products,
        products_quantity,
        loading_category_products,
        previous_category_id,
        xoffset,
        yoffset
    };


};

export default connect(mapStateToProps, {
    getCategoryProducts,
    clearCategoryProductsState,
    loadMoreCategoryProducts,
    exitCategoryProductsPage
})(CategoryProducts);