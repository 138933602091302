import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import _ from "lodash";
import {isMobile, isDesktop, isTablet} from 'react-device-detect'
import TopHeader from "./TopHeader";
import {Spinner, Card, Image,  OverlayTrigger, Popover, Button} from "react-bootstrap";
import {
    searchProduct,
    clearSearchProductState,
    exitSearchProductPage
} from "../actions";
import BottomHeader from "./BottomHeader";
import { makeProductBrandNameBold } from "../helpers";
import { Link } from "react-router-dom";
import {ScrollTop} from "./ScrollTop";


class SearchProduct extends Component{

    constructor(props){

        super(props);

        const history = props.history;

        const params = props.match.params;

        const location = props.location;

        const width = window.innerWidth;

        const height = window.innerHeight;


        this.state = {
            history,
            params,
            location,
            width,
            height
        };

    }


    componentWillUnmount(){

        console.log(this.state.history);

        const { history } = this.state;

        const {exitSearchProductPage, clearSearchProductState } = this.props;


        if(history.location.pathname.includes("/product/product_id")){

            console.log("exit search product page");

            exitSearchProductPage(window.pageXOffset, window.pageYOffset);

        }else{

           clearSearchProductState();

        }





    }


    getProducts(){

        let products = this.props.products;

        if(products.length % 3 === 0) {

            return products;

        }else{

            let nearest_multiple_3 = products.length;

            while(nearest_multiple_3 % 3 !== 0){

                products.push({});

                nearest_multiple_3 += 1;


            }

            return products;

        }



    }


    componentDidMount(){

        const { searching_product, products, searchProduct, xoffset, yoffset } = this.props;

        if(!searching_product && _.isEmpty(products)){

            searchProduct('');

        }

        if(xoffset !== null && yoffset !== null){

            window.scrollTo(xoffset, yoffset);

        }

    }


    renderPopOver(product){

        return(

            <Popover id={`popover-${product.id}`}>

                <Popover.Header as="h3">
                    {product.name}
                </Popover.Header>

                <Popover.Body style={{
                    whiteSpace: 'pre-line'
                }}>
                    { product.short_description.length >= 300 ? `${product.short_description}...` : product.short_description }
                </Popover.Body>

            </Popover>

        );

    }

    renderQuantity(product){

        let quantity = product.quantity;

        if(/^[0-9]\d*$/.test(quantity)){

            // Quantity is a natural number: 0, 1, 2, etc.

            quantity = parseInt(quantity);

            if(quantity === 0){

                return(

                    <p
                        style={{
                            color: '#d20505'
                        }}
                    >
                        Item is out of stock
                    </p>

                );

            }else{

                return(

                    <p
                        style={{
                            fontWeight: 'bold'
                        }}
                    >
                        Qty: {quantity}
                    </p>

                );

            }

        }

    }

    renderProductsList(){

        const products = isMobile ? this.props.products : this.getProducts();

        return _.map(products, (product, index) => {

            const position = index + 1;


            if(_.isEmpty(product)){

                return(

                    <Card
                        key={index}
                        style={{
                            flexBasis: this.state.width / 4,
                            margin: '15px',
                            visibility: 'hidden'
                        }}
                    />


                );


            }else{

                return(

                    <OverlayTrigger
                        key={product.id}
                        trigger={'hover' || 'focus'}
                        placement={ position % 3 === 0 ? 'left' : 'right'}
                        overlay={this.renderPopOver(product)}
                    >

                        <Link
                            to={`/product/product_id=${product.id}`}
                            style={{
                                textDecoration: 'none',
                                color: '#000000'
                            }}
                        >

                            <Card
                                key={index}
                                style={
                                    isMobile ?
                                        {
                                            width: this.state.width - 35,
                                            margin: '15px'
                                        } :
                                        {
                                            width: this.state.width / 4,
                                            margin: '15px'
                                        }}
                            >

                                <Card.Img variant="top" className="product-image" src={product.picture_url} />

                                <Card.Footer style={{
                                    height: '100px',
                                    paddingBottom: '30px'
                                }}>

                                    {makeProductBrandNameBold(product.short_name, product.brand_name)}

                                    {this.renderQuantity(product)}

                                </Card.Footer>


                            </Card>

                        </Link>





                    </OverlayTrigger>



                );



            }



        });

    }

    renderProducts(){

        const {
            products,
            searchProduct,
            product_name,
            search_quantity
        } = this.props;


        if(products !== null && products !== undefined && !_.isEmpty(products)){

            return(

                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>

                    <div style={ isMobile ? {
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'center',
                        flexDirection: 'column',
                        marginBottom: '3rem'
                    } : {
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'center',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        marginBottom: '3rem'
                    }}>

                        {this.renderProductsList()}



                    </div>

                    <Button
                        style={{
                            width: '10%',
                            borderRadius: '20px',
                            marginBottom: '3rem'
                        }}
                        variant="success"
                        onClick={() => {
                            searchProduct(product_name, search_quantity);
                            window.scrollBy(0, -0.001);
                        }}
                    >
                        Load More
                    </Button>

                </div>



            );


        }else{


            return (

                <div  style={{
                    marginBottom: isMobile ? '4rem' : this.state.height / 4,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    marginTop: isMobile ? 0:   this.state.height / 6
                }}>

                    <p style={{
                        textAlign: 'center',
                        fontSize:  isMobile ? '18px' : '22px'
                    }}>
                        There Were No Results Found
                    </p>

                    <p  style={{
                        textAlign: 'center',
                        fontSize:  isMobile ? '18px' : '22px'
                    }}>
                        Please check your spelling or use different keywords
                    </p>

                </div>

            );

        }



    }

    renderBody(){

        const { searching_product, initializing_user_page, products} = this.props;


        if(searching_product || initializing_user_page){

            return(

                <div
                    style={{
                        backgroundColor: '#f7f1eb'
                    }}
                >

                    <Spinner animation="border" variant="primary" className="spinner" />

                </div>

            );

        }else{

            return(

                <div style={ _.isEmpty(products) ?  {
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100vh',
                    backgroundColor: '#f7f1eb'
                } : {
                    backgroundColor: '#f7f1eb'
                }}>

                    <TopHeader
                        history={this.state.history}
                        params={this.state.params}
                        location={this.state.location}
                    />

                    {this.renderProducts()}

                    <BottomHeader
                        history={this.state.history}
                        params={this.state.params}
                        location={this.state.location}
                    />


                </div>

            )
        }

    }


    render() {


        return (

            <Fragment>

                {this.renderBody()}

                <ScrollTop/>

            </Fragment>

        );

    }

}

const mapStateToProps = (state) => {

    const {
        initializing_user_page
    } = state.user_pages;

    const {
        searching_product,
        products,
        product_name,
        search_quantity,
        xoffset,
        yoffset
    } = state.search_product;


    return {
        initializing_user_page,
        searching_product,
        products,
        product_name,
        search_quantity,
        xoffset,
        yoffset
    };


};

export default connect(mapStateToProps, {
    searchProduct,
    clearSearchProductState,
    exitSearchProductPage
})(SearchProduct);