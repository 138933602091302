import {
    CATEGORY_PRODUCTS_ROUTE,
    INITIALIZE_CATEGORY_PRODUCTS_PAGE,
    INITIALIZE_CATEGORY_PRODUCTS_PAGE_SUCCESS,
    CLEAR_CATEGORY_PRODUCTS_STATE,
    CATEGORY_PRODUCTS_QUANTITY_CHANGED,
    LOAD_CATEGORY_PRODUCTS,
    LOAD_CATEGORY_PRODUCTS_SUCCESS,
    EXIT_CATEGORY_PRODUCTS_PAGE
} from "./types";
import axios from "axios";
import { getFormData } from "../helpers";
import _ from "lodash";


export const exitCategoryProductsPage = (xoffset, yoffset) => {

    return{
        type: EXIT_CATEGORY_PRODUCTS_PAGE,
        payload: { xoffset: xoffset, yoffset: yoffset }
    };

};

export const clearCategoryProductsState = () => {

    return{
        type: CLEAR_CATEGORY_PRODUCTS_STATE
    }

};


export const loadMoreCategoryProducts = (category_id, products_quantity, history) => {

    return(dispatch) => {

        products_quantity = products_quantity + 15;

        dispatch({type: CATEGORY_PRODUCTS_QUANTITY_CHANGED, payload: products_quantity});

        dispatch({type: LOAD_CATEGORY_PRODUCTS});

        const config = {
            headers: {
                "Accept": "application/json"
            }
        };

        let bodyFormData = getFormData({
            id: category_id,
            quantity: products_quantity
        });

        axios.post(CATEGORY_PRODUCTS_ROUTE, bodyFormData, config)
            .then(response => {

                const data = response.data;

                console.log(data);

                const success = data.success;

                if(success){

                    const products = data.products;

                    if(products.length === 0){

                        history.push("/");

                        dispatch({type: CLEAR_CATEGORY_PRODUCTS_STATE});


                    }else{

                        dispatch({type: LOAD_CATEGORY_PRODUCTS_SUCCESS, payload: products});

                    }

                }else{

                    const message = data.message;

                    console.log(message);

                    history.push("/");

                    dispatch({type: CLEAR_CATEGORY_PRODUCTS_STATE});


                }


            }).catch(error => {

            history.push("/");

            dispatch({type: CLEAR_CATEGORY_PRODUCTS_STATE});

            console.log(error);

        });



    };

};


export const getCategoryProducts = (category_id, products_quantity, history) => {

    return(dispatch) => {

        dispatch({type: CLEAR_CATEGORY_PRODUCTS_STATE});

        dispatch({type: INITIALIZE_CATEGORY_PRODUCTS_PAGE});

        const config = {
            headers: {
                "Accept": "application/json"
            }
        };

        let bodyFormData = getFormData({
            id: category_id,
            quantity: products_quantity
        });

        axios.post(CATEGORY_PRODUCTS_ROUTE, bodyFormData, config)
            .then(response => {

                const data = response.data;

                console.log(data);

                const success = data.success;

                if(success){

                    const category_name = data.category_name;

                    const products = data.products;

                    if(products.length === 0){

                        history.push("/");

                        dispatch({type: CLEAR_CATEGORY_PRODUCTS_STATE});


                    }else{

                        dispatch({type: INITIALIZE_CATEGORY_PRODUCTS_PAGE_SUCCESS, payload: {
                            category_name: category_name,
                            products: products,
                            previous_category_id: category_id,
                            products_quantity: products_quantity
                        }});

                    }

                }else{

                    const message = data.message;

                    console.log(message);

                    history.push("/");

                    dispatch({type: CLEAR_CATEGORY_PRODUCTS_STATE});


                }


            }).catch(error => {

            history.push("/");

            dispatch({type: CLEAR_CATEGORY_PRODUCTS_STATE});

            console.log(error);

        });

    };

};