import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import _ from "lodash";
import {isMobile, isDesktop, isTablet} from 'react-device-detect'
import TopHeader from "./TopHeader";
import {Spinner, Card, Image, Button, OverlayTrigger, Popover, Breadcrumb, ButtonGroup, Form} from "react-bootstrap";
import {
    clearSendResetLinkState,
    sendResetLink
} from "../actions";
import BottomHeader from "./BottomHeader";
import {Helmet} from "react-helmet";
import 'react-multi-carousel/lib/styles.css';
import Recaptcha from 'react-google-invisible-recaptcha';
import {VERIFY_RECAPTCHA_TOKEN_ROUTE } from "../actions/types";
import axios from "axios";
import { getRecaptchaSiteKey } from "../helpers";

class SendResetLink extends Component{


    constructor(props){

        super(props);

        const history = props.history;

        const params = props.match.params;

        const location = props.location;

        const width = window.innerWidth;

        const height = window.innerHeight;

        const email = "";

        this.refRecaptcha = React.createRef();

        const verifying_recaptcha = false;

        const recaptcha_error = "";

        this.state = {
            history,
            params,
            location,
            width,
            height,
            email,
            verifying_recaptcha,
            recaptcha_error
        };

    }

    componentDidMount(){

        const { logged_in } = this.props;

        const { history } = this.state;

        if(logged_in){

            history.push("/");

        }

    }

    componentWillUnmount(){

        this.props.clearSendResetLinkState();

    }


    formMessages(){

        const {
            message,
            errors
        } = this.props;


        if(!_.isEmpty(message)){

            return(

                <p
                    style={{
                        color: '#32CD32',
                        marginBottom: '30px',
                        textAlign: 'center',
                        marginLeft: '10px'
                    }}
                >
                    {message}
                </p>


            );

        }else{

            return _.map(errors, (error, index) => {

                return(

                    <p
                        style={{
                            color: '#ff0000',
                            marginBottom: '30px'
                        }}
                        key={index}
                    >
                        {error}
                    </p>

                );

            });

        }


    }

    sendResetLink(){

        const {
            sendResetLink
        } = this.props;

        const { email } = this.state;

        sendResetLink(email);

    }

    recaptchaError(){

        const {  recaptcha_error } = this.state;

        if(recaptcha_error.length > 0){

            return(
                <p
                    style={{
                        fontSize: '18px',
                        color: '#FF0000',
                        textAlign: 'center',
                        marginBottom: '30px'
                    }}
                >
                    {recaptcha_error}
                </p>
            )

        }

    }


    renderSubmitButton(){

        const { loading } = this.props;

        const { verifying_recaptcha } = this.state;

        if(loading || verifying_recaptcha){

            return(

                <div style={{
                    marginBottom: '10px'
                }}>

                    <Spinner animation="border" variant="primary" />

                </div>

            );


        }else{

            return(

                <Fragment>


                    {this.recaptchaError()}

                    <Button
                        variant="outline-success"
                        style={{
                            width: isMobile ? this.state.width - 100 : '40%',
                            marginBottom: '30px',
                            borderRadius: '10px'
                        }}
                        onClick={() => {
                            this.refRecaptcha.current.execute();
                        }}
                    >
                        Submit
                    </Button>

                </Fragment>



            );


        }


    }

    renderBody(){

        const {
            initializing_user_page
        } = this.props;

        const { email } = this.state;

        if(initializing_user_page){

            return(

                <div
                    style={{
                        backgroundColor: '#f7f1eb'
                    }}
                >

                    <Spinner animation="border" variant="primary" className="spinner" />

                </div>

            );

        }else{

            return(

                <div style={{
                    backgroundColor: '#f7f1eb'
                }}>

                    <Helmet>

                        <title>Send Reset Link</title>

                    </Helmet>

                    <TopHeader
                        history={this.state.history}
                        params={this.state.params}
                        location={this.state.location}
                    />


                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: '4rem'
                        }}
                    >

                        <Card style={{
                            width: isMobile ?  this.state.width - 50 :  this.state.width / 2,
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}>

                            <Card.Body
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    marginBottom: '2rem',
                                    marginTop: '2rem'
                                }}
                            >

                                <p style={{
                                    fontSize:  isMobile ? '25px' : '30px',
                                    marginBottom: '2rem'
                                }}>
                                    Send Reset Link
                                </p>

                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                    }}
                                    style={{
                                        marginBottom: '1rem'
                                    }}
                                >


                                    <Form.Group
                                    >


                                        <Form.Control
                                            type="email"
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => {
                                                this.setState({email: e.target.value});
                                            }}
                                            style={{
                                                width: isMobile ? this.state.width - 100 : this.state.width / 3,
                                                marginBottom: '30px'
                                            }}
                                            onKeyPress={(e) => {

                                                if(e.charCode === 13){

                                                    e.preventDefault();

                                                    this.refRecaptcha.current.execute();

                                                }

                                            }}
                                        />

                                    </Form.Group>

                                </Form>


                                {this.formMessages()}

                                {this.renderSubmitButton()}


                            </Card.Body>

                        </Card>


                    </div>




                    <BottomHeader
                        history={this.state.history}
                        params={this.state.params}
                        location={this.state.location}
                    />

                    <Recaptcha
                        ref={this.refRecaptcha}
                        sitekey={getRecaptchaSiteKey()}
                        onResolved={() => {

                            this.setState({verifying_recaptcha: true, recaptcha_error: ''});

                            const token =  this.refRecaptcha.current.getResponse();

                            console.log(token);

                            const config = {
                                headers: {
                                    "Accept": "application/json"
                                }
                            };

                            let bodyFormData = new FormData();

                            bodyFormData.append('token', token);


                            axios.post(VERIFY_RECAPTCHA_TOKEN_ROUTE, bodyFormData, config)
                                .then(response => {

                                    const data = response.data;

                                    const success = data.success;

                                    this.setState({verifying_recaptcha: false});

                                    if(success){

                                        this.sendResetLink();

                                    }else{

                                        this.setState({recaptcha_error: 'An error occurred. Please try again.'});

                                    }



                                }).catch(error => {

                                console.log(error);

                                this.setState({recaptcha_error: 'An error occurred. Please try again.', verifying_recaptcha: false});

                            });


                        }}
                    />



                </div>

            )
        }



    }

    render() {


        return (

            <Fragment>

                {this.renderBody()}

            </Fragment>

        );

    }


}

const mapStateToProps = (state) => {

    const {
        initializing_user_page
    } = state.user_pages;

    const {
        loading,
        message,
        errors
    } = state.send_reset_link;

    const {
        logged_in
    } = state.login;

    return {
        initializing_user_page,
        loading,
        message,
        errors,
        logged_in
    };

};

export default connect(mapStateToProps, {
    clearSendResetLinkState,
    sendResetLink
})(SendResetLink);